import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Select from "react-select";
import { Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { getOfficeData } from '../../../redux/actions/LocationMaster/RegionalOffceAction';
import { getAgencyMaintenances } from '../../../redux/actions/AgencyMaintenance/AgencyMaintenanceAction';
import { getMajorGroups } from '../../../redux/actions/COAMasters/MajorGroupAction';
import { getStatusMaster } from "../../../redux/actions/OthersMaster/StatusMasterAction";
import CommonToast from '../../../CommonComponents/CommonToast/CommonToast';
import trimValue from '../../../utilities/trimValue';
import usePostMethod from '../../../hooks/usePostMethod';
import useGetMethod from '../../../hooks/useGetMethod';

const rewardStatusMaster = new Set([180, 181, 182, 183, 184, 185]);
const refundStatusMaster = new Set([190, 191, 192, 193, 194, 195, 196, 197, 198, 199]);
const replenishmentStatusMaster = new Set([170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 261]);
const disbursementStatusMaster = new Set([210, 211, 212, 213, 214, 215, 216, 217, 218, 251]);


const ReportFilterFields = ({ fields, filter, setFilter, validations, setReportUrl, noUrl=true }) => {
  const dispatch = useDispatch();
  const { currentUserData } = useSelector((state) => state?.findCurrentUserData);
  const { offices, loading: officesLoader } = useSelector((state) => state?.regionalOffice);
  const { agencyMaintenances, loading: agenciesLoader } = useSelector((state) => state?.agencyMaintenances);
  const { majorGroups, loading: majorGroupsLoader } = useSelector((state) => state?.majorGroups);
  const { statusMasters, loading: statusMastersLoader } = useSelector((state) => state?.statusMasters);
  const [{ data: rrcoAgencies, loading: rrcoAgenciesLoader }, getRrcoAgencies] = usePostMethod();
  const [{ data: { content: agencyUsers }, loading: agencyUsersLoader }, setAgencyUsersUrl] = useGetMethod();

  const [agencyList, setAgencyList] = useState([]);
  const [userList, setUserList] = useState([]);


  useEffect(() => {
    fields?.has("majorGroup") && dispatch(getMajorGroups());
    fields?.has("statusMaster") && dispatch(getStatusMaster());
    //eslint-disable-next-line
  }, [dispatch]);


  // Set user data
  useEffect(() => {
    if (fields?.has("regionalOffice")) {
      // DRC user
      if (currentUserData?.userEnumId === 1) {
        dispatch(getOfficeData());
      }
      // RRCO user
      else if (currentUserData?.userEnumId === 2) {
        setFilter(prev => ({
          ...prev,
          regionalOffice: {
            label: currentUserData?.regionalOffice?.rrcoName,
            value: currentUserData?.regionalOffice?.rrco_id,
          }
        }));
      }
    }

    if (fields?.has("agency")) {
      // DRC user
      if (currentUserData?.userEnumId === 1) {
        dispatch(getAgencyMaintenances(0));
      }
      // RRCO user
      else if (currentUserData?.userEnumId === 2 && currentUserData?.agenciesModelList === null) {
        getRrcoAgencies(`${process.env.REACT_APP_CORE_URL}/region/agencies`, { rrcoId: [currentUserData?.regionalOffice?.rrco_id] })
      }
      // Agency user
      else if (currentUserData?.currentAgency) {
        setFilter(prev => ({
          ...prev,
          agency: {
            label: currentUserData?.currentAgency?.agencyName,
            value: currentUserData?.currentAgency?.agencyId,
          }
        }));
        setAgencyUsersUrl(`/${process.env.REACT_APP_AUTH_URL}/auth/agency-user/${currentUserData?.currentAgency?.agencyId}`);
      }
    }

    // Set user type: drc = 1, rrco = 2, agency = 3
    setFilter(prev => ({
      ...prev,
      userType: currentUserData?.userEnumId === 1 ? 1
        : currentUserData?.userEnumId === 2 && currentUserData?.agenciesModelList === null ? 2
          : 3,
    }));
    //eslint-disable-next-line
  }, [currentUserData]);


  // Set all agency for DRC
  useEffect(() => {
    if (fields?.has("agency") && currentUserData?.userEnumId === 1) {
      setAgencyList(agencyMaintenances);
    }
    //eslint-disable-next-line
  }, [agencyMaintenances]);

  // Filter agency by rrco
  useEffect(() => {
    if (fields?.has("agency") && filter?.regionalOffice?.value) {
      setAgencyList(rrcoAgencies?.Agencies || []);
    }
    //eslint-disable-next-line
  }, [rrcoAgencies]);


  // Filter user by agency
  useEffect(() => {
    if (fields?.has("user") && filter?.agency?.value) {
      setUserList(agencyUsers || []);
    }
    //eslint-disable-next-line
  }, [agencyUsers]);


  const handleFilter = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    noUrl && setReportUrl("");
    CommonToast("dismiss", "");

    if (name === 'fromDate') {
      setFilter({
        ...filter,
        fromDateValue: value,
        [name]: value ? moment(value).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : ""
      });
    }
    else if (name === "toDate") {
      setFilter({
        ...filter,
        toDateValue: value,
        [name]: value ? moment(value).endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : ""
      });
    }
    else {
      setFilter({
        ...filter,
        [name]: value ? value : "",
      });
    }
  };

  const handleSelect = (list, name) => {
    noUrl && setReportUrl("");
    CommonToast("dismiss", "");

    if (name === "regionalOffice") {
      setFilter({
        ...filter,
        regionalOffice: list,
        agency: "",
        user: "",
      });
      if (list?.value) getRrcoAgencies(`${process.env.REACT_APP_CORE_URL}/region/agencies`, { rrcoId: [list?.value] });
    }
    else if (name === "agency") {
      setFilter({
        ...filter,
        agency: list,
        user: "",
      });
      if (list?.value) setAgencyUsersUrl(`/${process.env.REACT_APP_AUTH_URL}/auth/agency-user/${list?.value}`);
    }
    else {
      setFilter({
        ...filter,
        [name]: list,
      });
    }
  }


  return (
    <>
      {
        fields?.has("fromDate") &&
        <Col md={4}>
          <Form.Label className="form-label mt-0">
            From Date {validations?.has("fromDateRequired") && <span className="text-danger">*</span>}
          </Form.Label>
          <Form.Control
            required={validations?.has("fromDateRequired")}
            type="date"
            name="fromDate"
            onChange={handleFilter}
            value={filter?.fromDateValue || ""}
            max={filter?.toDateValue || ""}
          />
        </Col>
      }

      {
        fields?.has("toDate") &&
        <Col md={4}>
          <Form.Label className="form-label mt-0">
            To Date {validations?.has("toDateRequired") && <span className="text-danger">*</span>}
          </Form.Label>
          <Form.Control
            required={validations?.has("toDateRequired")}
            type="date"
            name="toDate"
            onChange={handleFilter}
            value={filter?.toDateValue || ""}
            min={filter?.fromDateValue || ""}
          />
        </Col>
      }

      {
        fields?.has("regionalOffice") &&
        <>
          {
            (currentUserData?.userEnumId === 1 || currentUserData?.userEnumId === 2) &&
            <Col md={4}>
              <Form.Label className="form-label mt-0">
                Regional Office {validations?.has("regionalOfficeRequired") && <span className="text-danger">*</span>}
              </Form.Label>
              <Select
                name="regionalOffice"
                required={validations?.has("regionalOfficeRequired")}
                closeMenuOnSelect={true}
                blurInputOnSelect={false}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder={officesLoader ? "Loading Regional Offices..." : "Select Regional Office"}
                isDisabled={officesLoader}
                onChange={(list) => handleSelect(list, "regionalOffice")}
                value={filter?.regionalOffice || ""}
                options={
                  // Only for DRC user
                  (currentUserData?.userEnumId === 1)
                    ? offices
                      ? offices?.filter((active) => active?.status === true)?.map((item) => ({
                        label: item?.rrcoName,
                        value: item?.rrco_id,
                      }))
                      :
                      []
                    :
                    // Only for RRCO user
                    (currentUserData?.userEnumId === 2)
                      ? [{
                        label: currentUserData?.regionalOffice?.rrcoName,
                        value: currentUserData?.regionalOffice?.rrco_id,
                      }]
                      :
                      []
                }
              />
            </Col>
          }
        </>
      }

      {
        fields?.has("agency") &&
        <Col md={4}>
          <Form.Label className="form-label mt-0">
            Agency {validations?.has("agencyRequired") && <span className="text-danger">*</span>}
          </Form.Label>
          <Select
            name="agency"
            required={validations?.has("agencyRequired")}
            closeMenuOnSelect={true}
            blurInputOnSelect={false}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder={(agenciesLoader || rrcoAgenciesLoader) ? "Loading Agencies..." : "Select Agency"}
            isDisabled={agenciesLoader || rrcoAgenciesLoader}
            onChange={(list) => handleSelect(list, "agency")}
            value={filter?.agency || ""}
            options={
              (
                // Only for DRC user
                (currentUserData?.userEnumId === 1)
                ||
                // Only RRCO without agency user
                (currentUserData?.userEnumId === 2 && currentUserData?.agenciesModelList === null)
              )
                ? agencyList
                  ? agencyList?.filter((active) => active?.status === true)?.map((item) => ({
                    label: item?.agencyName,
                    value: item?.agencyId,
                  }))
                  :
                  []
                :
                // Only Agency user
                currentUserData?.agenciesModelList
                  ? currentUserData?.agenciesModelList?.filter((active) => active?.status === true)?.map((item) => ({
                    label: item?.agencyName,
                    value: item?.agencyId,
                  }))
                  :
                  []
            }
          />
        </Col>
      }

      {
        fields?.has("user") &&
        <Col md={4}>
          <Form.Label className="form-label mt-0">
            User {validations?.has("userRequired") && <span className="text-danger">*</span>}
          </Form.Label>
          <Select
            name="user"
            required={validations?.has("userRequired")}
            closeMenuOnSelect={true}
            blurInputOnSelect={false}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder={agencyUsersLoader ? "Loading Users..." : "Select User"}
            isDisabled={!filter?.agency?.value || agencyUsersLoader}
            onChange={(list) => handleSelect(list, "user")}
            value={filter?.user || ""}
            options={
              userList
                ? userList?.map((item) => ({
                  label: item?.userName,
                  value: item?.userId,
                }))
                :
                []
            }
          />
        </Col>
      }

      {
        fields?.has("majorGroup") &&
        <Col md={4}>
          <Form.Label className="form-label mt-0">
            Major Group {validations?.has("majorGroupRequired") && <span className="text-danger">*</span>}
          </Form.Label>
          <Select
            name="majorGroup"
            required={validations?.has("majorGroupRequired")}
            closeMenuOnSelect={true}
            blurInputOnSelect={false}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder={majorGroupsLoader ? "Loading Major Groups..." : "Select Major Group"}
            isDisabled={majorGroupsLoader}
            onChange={(list) => handleSelect(list, "majorGroup")}
            value={filter?.majorGroup || ""}
            options={
              majorGroups
                ? majorGroups?.filter((active) => active?.status === true)?.map((item) => ({
                  label: item?.majorGroupName,
                  value: item?.majorGroupId,
                }))
                : []
            }
          />
        </Col>
      }

      {
        fields?.has("receiptType") &&
        <Col md={4}>
          <Form.Label className="form-label mt-0">
            Receipt Type {validations?.has("receiptTypeRequired") && <span className="text-danger">*</span>}
          </Form.Label>
          <Select
            name="receiptType"
            required={validations?.has("receiptTypeRequired")}
            closeMenuOnSelect={true}
            blurInputOnSelect={false}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder={"Select Receipt Type"}
            onChange={(list) => handleSelect(list, "receiptType")}
            value={filter?.receiptType || ""}
            options={[
              { label: "Manual Receipt", value: 1 },
              { label: "Preprinted Receipt", value: 2 },
            ]}
          />
        </Col>
      }

      {
        fields?.has("statusMaster") &&
        <Col md={4}>
          <Form.Label className="form-label mt-0">
            Status {validations?.has("statusMasterRequired") && <span className="text-danger">*</span>}
          </Form.Label>
          <Select
            name="statusMaster"
            required={validations?.has("statusMasterRequired")}
            closeMenuOnSelect={true}
            blurInputOnSelect={false}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder={statusMastersLoader ? "Loading Status..." : "Select Status"}
            isDisabled={statusMastersLoader}
            onChange={(list) => handleSelect(list, "statusMaster")}
            value={filter?.statusMaster || ""}
            options={
              statusMasters
                ? statusMasters?.filter((active) =>
                  active?.status === true &&
                  (
                    validations?.has("rewardStatusMaster") ? rewardStatusMaster?.has(active?.statusCode)
                      :
                      validations?.has("refundStatusMaster") ? refundStatusMaster?.has(active?.statusCode)
                        :
                        validations?.has("replenishmentStatusMaster") ? replenishmentStatusMaster?.has(active?.statusCode)
                          :
                          validations?.has("disbursementStatusMaster") ? disbursementStatusMaster?.has(active?.statusCode)
                            : true
                  )
                )?.map((item) => ({
                  label: item?.statusName,
                  value: item?.statusId,
                  code: item?.statusCode,
                }))
                : []
            }
          />
        </Col>
      }

      {
        fields?.has("vendorType") &&
        <Col md={4}>
          <Form.Label className="form-label mt-0">
            Vendor Type {validations?.has("vendorTypeRequired") && <span className="text-danger">*</span>}
          </Form.Label>
          <Select
            name="vendorType"
            required={validations?.has("vendorTypeRequired")}
            closeMenuOnSelect={true}
            blurInputOnSelect={false}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder={"Select Vendor Type"}
            onChange={(list) => handleSelect(list, "vendorType")}
            value={filter?.vendorType || ""}
            options={[
              { label: "Official", value: 1 },
              { label: "Non-Official", value: 2 },
              { label: "VIP", value: 3 },
            ]}
          />
        </Col>
      }

      {
        fields?.has("vendorCode") &&
        <Col md={4}>
          <Form.Label className="form-label mt-0">
            Vendor Code {validations?.has("vendorCodeRequired") && <span className="text-danger">*</span>}
          </Form.Label>
          <Form.Control
            name="vendorCode"
            required={validations?.has("vendorCodeRequired")}
            placeholder="Enter Vendor Code"
            onChange={handleFilter}
            value={filter?.vendorCode || ""}
            onBlur={() => trimValue("vendorCode", filter, setFilter)}
          />
        </Col>
      }

      {
        fields?.has("voucherNo") &&
        <Col md={4}>
          <Form.Label className="form-label mt-0">
            {
              validations?.has("rewardVoucherLabel") ? "Payment/ Reward "
                : validations?.has("refundVoucherLabel") ? "Payment/ Refund "
                  : validations?.has("disbursementVoucherLabel") ? "Disbursement "
                    : " "
            }
            Voucher No. {validations?.has("voucherNoRequired") && <span className="text-danger">*</span>}
          </Form.Label>
          <Form.Control
            name="voucherNo"
            required={validations?.has("voucherNoRequired")}
            placeholder="Enter Voucher No."
            onChange={handleFilter}
            value={filter?.voucherNo || ""}
            onBlur={() => trimValue("voucherNo", filter, setFilter)}
          />
        </Col>
      }

      {
        fields?.has("documentNo") &&
        <Col md={4}>
          <Form.Label className="form-label mt-0">
            TPN/ CID/ Work Permit/ Passport No. {validations?.has("documentNoRequired") && <span className="text-danger">*</span>}
          </Form.Label>
          <Form.Control
            name="documentNo"
            required={validations?.has("documentNoRequired")}
            placeholder="Enter TPN/ CID/ Work Permit/ Passport No."
            onChange={handleFilter}
            value={filter?.documentNo || ""}
            onBlur={() => trimValue("documentNo", filter, setFilter)}
          />
        </Col>
      }
    </>
  );
};

export default ReportFilterFields;