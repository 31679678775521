import { useEffect, useState } from "react";
import axiosRequest from "../services/Interceptor/axiosRequest";


const useGetMethod = (defaultUrl = "") => {
    // Use defaultUrl to call api on mount
    const [url, setUrl] = useState(defaultUrl);
    const [refetch, setRefetch] = useState(false); // New refetch state
    const [result, setResult] = useState({
        loading: defaultUrl ? true : false,
        data: {},
    });

    useEffect(() => {
        // Call api if url is found else reset state
        if (url) {
            setResult((result) => ({
                ...result,
                loading: true,
            }));

            axiosRequest
                .get(url)
                .then((response) => {
                    setResult((result) => ({
                        ...result,
                        loading: false,
                        data: response?.data || {},
                    }));
                })
                .catch((error) => {
                    setResult((result) => ({
                        ...result,
                        loading: false,
                        data: {},
                        error: error,
                    }));
                });
        } else {
            setResult((result) => ({
                ...result,
                loading: false,
                data: {},
            }));
        }
        //eslint-disable-next-line
    }, [url, refetch])

    // First index returns result state with data, loading, and optionally error
    // Second index returns a function to set-url manually and refetch data, set empty string ("") to reset the result state
    return [
        result,
        (newUrl) => {
            setUrl(newUrl);
            setRefetch((prevRefetch) => !prevRefetch); // Toggle the refetch to force useEffect and refetch data
        },
    ];
};

export default useGetMethod;