import React from "react";

const TaxToGDPTable = ({ tableData, label }) => {

  return (
    <>
      <div>
        <div className="card ">
          <div className="table-responsive">
            <table className="table table-bordered">
              <tbody>
              <tr className="text-center">
                  <th colSpan={10} className="text-center">Nominal GDP</th>
                </tr>
                <tr className="text-center">
                  <th>Sl. No.</th>
                  <th>Revenue Sub Type</th>
                  <th>Amount (Nu. in Millions)</th>
                  <th>Tax to GDP %</th>
                </tr>

                {tableData?.dataList?.map((list, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="text-nowrap">{list?.subType || ""}</td>
                    <td className="text-end">{list?.amount || 0}</td>
                    <td className="text-end">{list?.tax || 0}</td>
                  </tr>
                ))}
                <tr>
                  <th colSpan={3} className="text-center">
                    Total ax to GDP %
                  </th>
                  <td className="text-end">
                    {tableData?.totalTax}
                  </td>
                 
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaxToGDPTable;
