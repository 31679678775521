import {
  CREATE_AGENCY_MAINTENANCE_REQUEST,
  CREATE_AGENCY_MAINTENANCE_SUCCESS,
  CREATE_AGENCY_MAINTENANCE_FAIL,

  AGENCY_MAINTENANCE_REQUEST,
  AGENCY_MAINTENANCE_SUCCESS,
  AGENCY_MAINTENANCE_FAIL,

  FIND_AGENCY_MAINTENANCE_REQUEST,
  FIND_AGENCY_MAINTENANCE_SUCCESS,
  FIND_AGENCY_MAINTENANCE_FAIL,

  UPDATE_AGENCY_MAINTENANCE_REQUEST,
  UPDATE_AGENCY_MAINTENANCE_SUCCESS,
  UPDATE_AGENCY_MAINTENANCE_FAIL,

  AGENCY_MAINTENANCE_CLEAR_ERROR,

} from "../../constants/AgencyMaintenance/AgencyMaintenanceConstant";
import axiosRequest from "../../../services/Interceptor/axiosRequest";

//Add 
export const createAgencyMaintenance = (data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_AGENCY_MAINTENANCE_REQUEST });
    const res = await axiosRequest.post(`/${process.env.REACT_APP_CORE_URL}/agencies/create`, data);
    if (res?.data?.statusCode !== 409) {
      dispatch({  //success
        type: CREATE_AGENCY_MAINTENANCE_SUCCESS,
        payload: res.data.content,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } else {
      dispatch({
        type: CREATE_AGENCY_MAINTENANCE_FAIL, //For conflict
        message: res.data.message,
        status: res.data.statusCode,
      });
    }
  } catch (error) {
    dispatch({
      type: CREATE_AGENCY_MAINTENANCE_FAIL,
      payload: error,
    });
  }
};

//Get All 
export const getAgencyMaintenances = (filterType) => async (dispatch) => {
  try {
    dispatch({ type: AGENCY_MAINTENANCE_REQUEST });
    const res = await axiosRequest.get(`/${process.env.REACT_APP_CORE_URL}/agencies/type/${filterType}`);
    dispatch({ type: AGENCY_MAINTENANCE_SUCCESS, payload: res.data.content });
  } catch (error) {
    dispatch({
      type: AGENCY_MAINTENANCE_FAIL,
      payload: error,
    });
  }
};

// Find Data
export const findAgencyMaintenance = (id) => async (dispatch) => {
  try {
    dispatch({ type: FIND_AGENCY_MAINTENANCE_REQUEST });
    const res = await axiosRequest.get(`/${process.env.REACT_APP_CORE_URL}/agencies/${id}`);
    dispatch({ type: FIND_AGENCY_MAINTENANCE_SUCCESS, payload: res.data.content });
  } catch (error) {
    dispatch({
      type: FIND_AGENCY_MAINTENANCE_FAIL,
      payload: error,
    });
  }
};

//  update
export const updateAgencyMaintenance = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_AGENCY_MAINTENANCE_REQUEST });
    const res = await axiosRequest.put(`/${process.env.REACT_APP_CORE_URL}/agencies/${id}`, data);
    if (res?.data?.statusCode !== 409) {
      dispatch({
        type: UPDATE_AGENCY_MAINTENANCE_SUCCESS,
        payload: res.data.content,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } else {
      dispatch({
        type: UPDATE_AGENCY_MAINTENANCE_FAIL, //For conflict
        message: res.data.message,
        status: res.data.statusCode,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_AGENCY_MAINTENANCE_FAIL,
      payload: error,
    });
  }
};

export const clearError = () => async (dispatch) => {
  dispatch({ type: AGENCY_MAINTENANCE_CLEAR_ERROR });
};
