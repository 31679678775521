import {
  REGIONAL_OFFICE_REQUEST,
  REGIONAL_OFFICE_SUCCESS,
  REGIONAL_OFFICE_FAIL,
  REGIONAL_OFFICE_CREATE_REQUEST,
  REGIONAL_OFFICE_CREATE_SUCCESS,
  REGIONAL_OFFICE_CREATE_FAIL,
  FIND_REGIONAL_OFFICE_REQUEST,
  FIND_REGIONAL_OFFICE_SUCCESS,
  FIND_REGIONAL_OFFICE_FAIL,
  UPDATE_REGIONAL_OFFICE_REQUEST,
  UPDATE_REGIONAL_OFFICE_SUCCESS,
  UPDATE_REGIONAL_OFFICE_FAIL,
  DELETE_REGIONAL_OFFICE_REQUEST,
  DELETE_REGIONAL_OFFICE_SUCCESS,
  DELETE_REGIONAL_OFFICE_FAIL,
  CLEAR_ERROR,
} from "../../constants/LocationMaster/RegionalOfficeConstant";
import axiosRequest from "../../../services/Interceptor/axiosRequest";

//Add Regional Office
export const createOfficeData = (data) => async (dispatch) => {
  try {
    dispatch({ type: REGIONAL_OFFICE_CREATE_REQUEST });
    const res = await axiosRequest.post(
      `/${process.env.REACT_APP_CORE_URL}/region/create`,
      data
    );

    if (res?.data?.statusCode !== 409) {
      dispatch({
        type: REGIONAL_OFFICE_CREATE_SUCCESS,
        payload: res.data.content,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } else {
      dispatch({
        type: REGIONAL_OFFICE_CREATE_FAIL, //For conflict
        message: res.data.message,
        status: res.data.statusCode,
      });
    }
  } catch (error) {
    dispatch({
      type: REGIONAL_OFFICE_CREATE_FAIL,
      payload: error,
    });
  }
};

//Get All in Regional Office
export const getOfficeData = () => async (dispatch) => {
  try {
    dispatch({ type: REGIONAL_OFFICE_REQUEST });
    const res = await axiosRequest.get(
      `/${process.env.REACT_APP_CORE_URL}/region/all`
    );

    dispatch({
      type: REGIONAL_OFFICE_SUCCESS,
      payload: res?.data?.content,
    });
  } catch (error) {
    dispatch({
      type: REGIONAL_OFFICE_FAIL,
      payload: error,
    });
  }
};

//Get Find Data in Regional Office
export const findOfficeData = (id) => async (dispatch) => {
  try {
    dispatch({ type: FIND_REGIONAL_OFFICE_REQUEST });
    const res = await axiosRequest.get(
      `/${process.env.REACT_APP_CORE_URL}/region/${id}`
    );

    dispatch({
      type: FIND_REGIONAL_OFFICE_SUCCESS,
      payload: res.data.content,
    });
  } catch (error) {
    dispatch({
      type: FIND_REGIONAL_OFFICE_FAIL,
      payload: error,
    });
  }
};

//Update Find Data in Regional Office
export const updateOfficeData = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_REGIONAL_OFFICE_REQUEST });
    const res = await axiosRequest.put(
      `/${process.env.REACT_APP_CORE_URL}/region/${id}`,
      data
    );

    if (res?.data?.statusCode !== 409) {
      dispatch({
        type: UPDATE_REGIONAL_OFFICE_SUCCESS,
        payload: res.data.content,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } else {
      dispatch({
        type: UPDATE_REGIONAL_OFFICE_FAIL, //For conflict
        message: res.data.message,
        status: res.data.statusCode,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_REGIONAL_OFFICE_FAIL,
      payload: error,
    });
  }
};

//delete Data in Regional Office

export const deleteOfficeData = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_REGIONAL_OFFICE_REQUEST });

    const res = await axiosRequest.put(
      `/${process.env.REACT_APP_CORE_URL}/region/delete/${id}`,
      formData
    );

    if (
      res?.data?.statusCode !== 409 &&
      res?.data?.content?.isDelete === true
    ) {
      dispatch({
        type: DELETE_REGIONAL_OFFICE_SUCCESS,
        payload: id,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } else if (
      res?.data?.statusCode !== 409 &&
      res?.data?.content?.isDelete === false
    ) {
      dispatch({
        type: "FUTURE_DELETE_REGIONAL_OFFICE_SUCCESS",
        payload: res?.data?.content,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } else {
      dispatch({
        type: DELETE_REGIONAL_OFFICE_FAIL,
        message: res.data.message,
        status: res.data.statusCode,
      });
    }
  } catch (error) {
    dispatch({
      type: DELETE_REGIONAL_OFFICE_FAIL,
      payload: error,
    });
  }
};

export const clearError = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERROR });
};
