import React from "react";

const BudgetedTargetsTable = ({ tableData, label }) => {

  return (
    <>
      <div>
        <div className="card ">
          <div className="table-responsive">
            <table className="table table-bordered">
              <tbody>
              <tr className="text-center">
                  <th colSpan={10} className="text-center">Projection ({label})</th>
                </tr>
                <tr className="text-center">
                  <th>Sl. No.</th>
                  <th>Revenue Sub Type</th>
                  <th>Budgeted Target</th>
                  <th>Thimphu</th>
                  <th>Paro</th>
                  <th>Phuntsholing</th>
                  <th>Samdrupjongkhar</th>
                  <th>Samtse</th>
                  <th>Mongar</th>
                  <th>Bumthang</th>
                </tr>

                {tableData?.dataList?.map((list, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="text-nowrap">{list?.subType || ""}</td>
                    <td className="text-end">{list?.budgetedTarget || 0}</td>
                    <td className="text-end">{list?.thimphu || 0}</td>
                    <td className="text-end">{list?.paro || 0}</td>
                    <td className="text-end">{list?.phuentsholing || 0}</td>
                    <td className="text-end">{list?.samdrupjongkhar || 0}</td>
                    <td className="text-end">{list?.samtse || 0}</td>
                    <td className="text-end">{list?.mongar || 0}</td>
                    <td className="text-end">{list?.bumthang || 0}</td>
                  </tr>
                ))}
                <tr>
                  <th colSpan={2} className="text-center">
                    Total
                  </th>
                  <td className="text-end">
                    {tableData?.totalBudgetedTarget}
                  </td>
                  <td className="text-end">{tableData?.totalThimphu}</td>
                  <td className="text-end">{tableData?.totalParo}</td>
                  <td className="text-end">
                    {tableData?.totalPhuentsholing}
                  </td>
                  <td className="text-end">
                    {tableData?.totalSamdrupjongkhar}
                  </td>
                  <td className="text-end">{tableData?.totalSamtse}</td>
                  <td className="text-end">{tableData?.totalMongar}</td>
                  <td className="text-end">{tableData?.totalBumthang}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default BudgetedTargetsTable;
