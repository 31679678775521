import CommonToast from "../CommonComponents/CommonToast/CommonToast";
import axiosRequest from "../services/Interceptor/axiosRequest";
const TIMEOUT_DURATION = 600000; // 10 minutes in milliseconds

export const pdfDownload = async (url, fileName, service, setIsDownloading) => {
  try {
    setIsDownloading(true);

    const response = await axiosRequest.get(`/${service}/${url}`, {
      responseType: "arraybuffer", // Set responseType to "arraybuffer" for binary data (PDF)
      timeout: TIMEOUT_DURATION 
    });

    if (response.status === 200) {
      const uint8Array = new Uint8Array(response.data);
      const blob = new Blob([uint8Array], {
        type: "application/pdf", // Set the type to "application/pdf" for PDF files
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}.pdf`; // Set the desired PDF file name
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      setIsDownloading(false);
    }
  } catch (error) {
    if (error.response?.status === 409) {
      CommonToast("conflict", "N0 Data Found...");
    } else {
      console.error("An error occurred:", error);
    }
    setIsDownloading(false);
  }
};
