import { useState } from "react";
import axiosRequest from "../services/Interceptor/axiosRequest";

const usePostMethod = () => {
  const [result, setResult] = useState({
    loading: false,
    data: null,
    status: null,
    message: null,
  });

  const create = (url = "", payload) => {
    if (url && payload) {
      setResult((prevResult) => ({
        ...prevResult,
        loading: true,
        data: null,
        status: null,
        message: null,
        error: null,
      }));

      axiosRequest
        .post(url, payload)
        .then((response) => {
          setResult((prevResult) => ({
            ...prevResult,
            loading: false,
            data: response?.data?.content || null,
            status: response?.data?.statusCode || null,
            message: response?.data?.message || null,
          }));
        })
        .catch((error) => {
          setResult((prevResult) => ({
            ...prevResult,
            loading: false,
            data: null,
            status: null,
            message: null,
            error: error,
          }));
        });
    }
  }

  // First index returns result state with data, loading, status code, message and optionally error
  // Second index returns a create function that takes two arguments, first is the url second is the payload
  return [result, create];
};

export default usePostMethod;