import axiosRequest from "../../../services/Interceptor/axiosRequest";
import {
  CREATE_REV_SUB_TYPE_REQUEST,
  CREATE_REV_SUB_TYPE_SUCCESS,
  CREATE_REV_SUB_TYPE_FAIL,
  REV_SUB_TYPE_REQUEST,
  REV_SUB_TYPE_SUCCESS,
  REV_SUB_TYPE_FAIL,
  FIND_REV_SUB_TYPE_REQUEST,
  FIND_REV_SUB_TYPE_SUCCESS,
  FIND_REV_SUB_TYPE_FAIL,
  UPDATE_REV_SUB_TYPE_REQUEST,
  UPDATE_REV_SUB_TYPE_SUCCESS,
  UPDATE_REV_SUB_TYPE_FAIL,
  DELETE_REV_SUB_TYPE_REQUEST,
  DELETE_REV_SUB_TYPE_SUCCESS,
  DELETE_REV_SUB_TYPE_FAIL,
  CLEAR_ERROR,
} from "../../constants/COAMasters/RevSubTypeConstant";

// create
export const createRevSubType = (data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_REV_SUB_TYPE_REQUEST });

    const res = await axiosRequest.post(
      `/${process.env.REACT_APP_CORE_URL}/revenue-sub-type/create`,
      data
    );

    if (res?.data?.statusCode !== 409) {
      dispatch({
        type: CREATE_REV_SUB_TYPE_SUCCESS,
        payload: res.data.content,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } else {
      dispatch({
        type: CREATE_REV_SUB_TYPE_FAIL, //For conflict
        message: res.data.message,
        status: res.data.statusCode,
      });
    }
  } catch (error) {
    dispatch({
      type: CREATE_REV_SUB_TYPE_FAIL,
      payload: error,
    });
  }
};

// get
export const getRevSubTypes = () => async (dispatch) => {
  try {
    dispatch({ type: REV_SUB_TYPE_REQUEST });

    const res = await axiosRequest.get(
      `/${process.env.REACT_APP_CORE_URL}/revenue-sub-type/all`
    );

    dispatch({
      type: REV_SUB_TYPE_SUCCESS,
      payload: res.data.content,
    });
  } catch (error) {
    dispatch({
      type: REV_SUB_TYPE_FAIL,
      payload: error,
    });
  }
};

// find
export const findRevSubType = (id) => async (dispatch) => {
  try {
    dispatch({ type: FIND_REV_SUB_TYPE_REQUEST });

    const res = await axiosRequest.get(
      `/${process.env.REACT_APP_CORE_URL}/revenue-sub-type/${id}`
    );

    dispatch({
      type: FIND_REV_SUB_TYPE_SUCCESS,
      payload: res.data.content,
    });
  } catch (error) {
    dispatch({
      type: FIND_REV_SUB_TYPE_FAIL,
      payload: error,
    });
  }
};

// update
export const updateRevSubType = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_REV_SUB_TYPE_REQUEST });

    const res = await axiosRequest.put(
      `/${process.env.REACT_APP_CORE_URL}/revenue-sub-type/${id}`,
      data
    );

    if (res?.data?.statusCode !== 409) {
      dispatch({
        type: UPDATE_REV_SUB_TYPE_SUCCESS,
        payload: res.data.content,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } else {
      dispatch({
        type: UPDATE_REV_SUB_TYPE_FAIL, //For conflict
        message: res.data.message,
        status: res.data.statusCode,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_REV_SUB_TYPE_FAIL,
      payload: error,
    });
  }
};

// delete
export const deleteRevSubType = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_REV_SUB_TYPE_REQUEST });

    const res = await axiosRequest.put(
      `/${process.env.REACT_APP_CORE_URL}/revenue-sub-type/delete/${id}`,
      formData
    );
    if (
      res?.data?.statusCode !== 409 &&
      res?.data?.content?.isDelete === true
    ) {
      dispatch({
        type: DELETE_REV_SUB_TYPE_SUCCESS,
        payload: id,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } else if (
      res?.data?.statusCode !== 409 &&
      res?.data?.content?.isDelete === false
    ) {
      dispatch({
        type: "FUTURE_DELETE_REV_SUB_TYPE_SUCCESS",
        payload: res?.data?.content,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } else {
      dispatch({
        type: DELETE_REV_SUB_TYPE_FAIL,
        message: res.data.message,
        status: res.data.statusCode,
      });
    }
  } catch (error) {
    dispatch({
      type: DELETE_REV_SUB_TYPE_FAIL,
      payload: error,
    });
  }
};

export const clearError = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERROR });
};
