import React from 'react';
import { Card } from "react-bootstrap";

const RequestLoader = ({style}) => {
    return (
        <Card className='d-flex align-items-center justify-content-center' style={{ position: "absolute", inset: "0", opacity:"0.8", marginBottom:"0px", zIndex:"100", ...style}}>
            <div>
                <div className="text-center">
                    <div className="lds-spinner">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default RequestLoader;