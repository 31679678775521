import React, { useEffect, useState } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import CommonSubHeader from "../../../CommonComponents/CommonSubHeader/CommonSubHeader";
import MetaData from "../../../CommonComponents/MetaData/MetaData";
import CommonToast from "../../../CommonComponents/CommonToast/CommonToast";
import { excelDownload } from "../../../utilities/excelDownload";
import { pdfDownload } from "../../../utilities/pdfDownload";
import useGetMethod from "../../../hooks/useGetMethod";
import TaxToGDPTable from "./TaxToGDPTable";
import RequestLoader from "../../../shade/Loaders/RequestLoader";
import ReportHeader from "../ReportComponents/ReportHeader";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getRevSubTypes } from "../../../redux/actions/COAMasters/RevSubTypeAction";
import { getFinancialYears } from "../../../redux/actions/OthersMaster/FinancialYearsAction";
import { getRevisionQuarter } from "../../../redux/actions_Phase_2/RevenueForecast/RevenueForecastAction";

const TaxToGDP = () => {
  const dispatch = useDispatch();
  const { revSubTypes, loading } = useSelector((state) => state?.revSubTypes);
  const { financialYears, financialLoading } = useSelector(
    (state) => state?.financialYears
  );

  const { quarterList } = useSelector((state) => state?.quarterList);
  const [subType, setSubType] = useState([]);
  const [target, setTarget] = useState([]);
  const [quarterId, setQuarterId] = useState([]);
  const [financialId, setFinancialId] = useState([]);

  const [
    {
      data: {
        content: reportData,
        statusCode: reportStatus,
        message: reportMessage,
      },
      loading: reportLoader,
    },
    setReportUrl,
  ] = useGetMethod();

  const [tableData, setTableData] = useState([]);
  const [pdfLoader, setPdfLoader] = useState(false);
  const [excelLoader, setExcelLoader] = useState(false);
  const targetType = [
    {
      targetId: 1,
      targetName: "Initiated Target",
    },
    {
      targetId: 2,
      targetName: "Revised Target",
    },
  ];

  useEffect(() => {
    dispatch(getRevSubTypes());
    dispatch(getFinancialYears());
    dispatch(getRevisionQuarter());
  }, [dispatch]);

  // controlling dynamic function
  const handleSearch = (event) => {
    event.preventDefault();
    const submitButton = event.nativeEvent.submitter;
    const buttonText = submitButton.innerText;
    switch (buttonText) {
      case "View":
        handleView();
        break;
      case "Excel":
        handleExcelDownload();
        break;
      case "PDF":
        handlePdfDownload();
        break;
      default:
        break;
    }
  };

  const handleView = () => {
    setReportUrl(
      `/${process.env.REACT_APP_REPORT_URL}/tax-to-gdp/get-data?targetType=${
        target?.value
      }&fiscalYear=${financialId?.value}&revenueSubType=${
        subType?.value || 0
      }&revision=${quarterId?.value || 0}`
    );
  };

  useEffect(() => {
    if (reportStatus === 409) {
      CommonToast("conflict", reportMessage);
      setTableData([]);
    } else {
      setTableData(reportData || []);
    }
  }, [reportData, reportStatus, reportMessage]);

  const handleExcelDownload = () => {
    excelDownload(
      `tax-to-gdp/download-excel?targetType=${target?.value}&fiscalYear=${
        financialId?.value
      }&revenueSubType=${subType?.value || 0}&revision=${
        quarterId?.value || 0
      }`,
      "Tax To GDP",
      process.env.REACT_APP_REPORT_URL,
      setExcelLoader
    );
  };

  const handlePdfDownload = () => {
    pdfDownload(
      `tax-to-gdp/download-pdf?targetType=${target?.value}&fiscalYear=${
        financialId?.value
      }&revenueSubType=${subType?.value || 0}&revision=${
        quarterId?.value || 0
      }&fiscalYearInWords=${financialId?.label}`,
      "Tax To GDP",
      process.env.REACT_APP_REPORT_URL,
      setPdfLoader
    );
  };

  return (
    <div className="main-container container-fluid">
      <MetaData title="Tax To GDP" />
      <div className="d-print-none">
        <CommonSubHeader
          title="Tax To GDP"
          item="Reports"
          subItem="Tax To GDP"
        />
      </div>

      <Form onSubmit={handleSearch}>
        <div className="card pd-30">
          {reportLoader && <RequestLoader />}
          <Row className="gy-3">
            <Col md={4}>
              <Form.Label className="form-label mt-0">
                Revenue Sub Type
              </Form.Label>
              <Select
                name="subType"
                closeMenuOnSelect={true}
                blurInputOnSelect={false}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder={
                  loading ? "Loading Sub Types..." : "Select Sub Type"
                }
                isDisabled={loading}
                onChange={(list) => setSubType(list)}
                value={subType?.value ? subType : []}
                options={
                  revSubTypes
                    ? revSubTypes
                        ?.filter((active) => active?.status === true)
                        ?.map((item) => ({
                          label: item?.revenueSubTypeName,
                          value: item?.revenueSubTypeId,
                        }))
                    : []
                }
              />
            </Col>

            <Col md={4}>
              <Form.Label className="form-label mt-0">
                Target Type <span className="text-danger">*</span>
              </Form.Label>
              <Select
                name="target"
                required
                closeMenuOnSelect={true}
                blurInputOnSelect={false}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder={
                  loading ? "Loading Target Types..." : "Select Target Type"
                }
                isDisabled={loading}
                onChange={(list) => {
                  setTarget(list);
                  setQuarterId([]);
                }}
                value={target?.value ? target : []}
                options={
                  targetType
                    ? targetType?.map((item) => ({
                        label: item?.targetName,
                        value: item?.targetId,
                      }))
                    : []
                }
              />
            </Col>

            {target?.value === 2 && (
              <Col md={4}>
                <Form.Label className="form-label mt-0">
                  Revision <span className="text-danger">*</span>
                </Form.Label>
                <Select
                  required
                  closeMenuOnSelect={true}
                  blurInputOnSelect={false}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  name="quarterId"
                  placeholder="Select quarter"
                  onChange={(list) => {
                    setQuarterId(list);
                  }}
                  value={quarterId || ""}
                  options={
                    quarterList?.map((item) => ({
                      label: item?.quarterName,
                      value: item?.quarterId,
                    })) || []
                  }
                />
              </Col>
            )}

            <Col md={4}>
              <Form.Label className="form-label mt-0">
                Financial Year <span className="text-danger">*</span>
              </Form.Label>
              <Select
                required
                closeMenuOnSelect={true}
                blurInputOnSelect={false}
                className="basic-multi-select"
                classNamePrefix="select"
                name="financialId"
                placeholder={
                  financialLoading
                    ? "Loading Financial Years..."
                    : "Select Financial Year"
                }
                onChange={(list) => setFinancialId(list)}
                value={financialId || ""}
                options={
                  financialYears
                    ? financialYears
                        ?.filter((active) => active?.status === true)
                        ?.map((item) => ({
                          label: item?.financialYear,
                          value: item?.financialYearId,
                        }))
                    : []
                }
              />
            </Col>

            <Col
              md={12}
              className="d-flex align-items-center justify-content-end mg-t-30 gap-3"
            >
              <Button className="btn btn-primary pd-x-30" type="submit">
                View
              </Button>
              <Button
                type="submit"
                disabled={excelLoader}
                className="btn btn-primary pd-x-30"
              >
                {excelLoader ? "Downloading" : "Excel"}
              </Button>
              <Button
                type="submit"
                className="btn btn-primary pd-x-30"
                disabled={pdfLoader}
              >
                {pdfLoader ? "Downloading" : "PDF"}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>

      <Row className="my-4">
        <Col lg={12}>
          <div className="card pd-30">
            {reportData?.dataList?.length > 0 &&
              !tableData?.dataList?.length && <RequestLoader />}
            {tableData?.dataList?.length > 0 ? (
              <>
                <ReportHeader />

                <TaxToGDPTable
                  tableData={tableData}
                  label={financialId?.label}
                />
              </>
            ) : (
              <div className="text-center text-primary py-5 tx-semibold">
                Please fetch from above ...
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TaxToGDP;
