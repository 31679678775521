import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import secureLocalStorage from "react-secure-storage";
import { userReducer } from "../reducers/userReducer";
import { getCOAGroupsReducer, findCOAGroupReducer } from "../reducers/COAMasters/COAGroupsReducer";
import { getModeOfPaymentReducer, findModeOfPaymentReducer } from "../reducers/OthersMaster/ModeOfPaymentReducer";
import { getBusinessSectorReducer, findBusinessSectorReducer } from "../reducers/OthersMaster/BusinessSectorReducer";
import { findFinancialYearReducer, getFinancialYearReducer } from "../reducers/OthersMaster/FinancialYearsReducer";
import { getCountryReducer, findCountryReducer } from "../reducers/OthersMaster/CountryReducer";
import { findRegionalOffceReducer, getRegionalOffceReducer } from "../reducers/LocationMaster/RegionalOffceReducer";
import { getConfigOptionsReducer, findConfigOptionReducer } from "../reducers/COAMasters/ConfigOptionReducer";
import { getCurrenciesReducer, findCurrencyReducer } from "../reducers/OthersMaster/CurrencyReducer";
import { getTaxTypeReducer, findTaxTypeReducer } from "../reducers/OthersMaster/TaxTypeMasterReducer";
import { dzongkhageReducer, finddzongkhageReducer } from "../reducers/LocationMaster/DzongkhageReducer";
import { dungkhagReducer, findDungkhagReducer } from "../reducers/LocationMaster/dungkhagReducer";
import { getTaxProcessReducer, findTaxProcessReducer } from "../reducers/OthersMaster/TaxProcessReducer";
import { getPaymentMethodReducer, findPaymentMethodReducer } from "../reducers/OthersMaster/PaymentMethodReducer";
import { findMajorGroupReducer, majorGroupReducer } from "../reducers/COAMasters/MajorGroupReducer";
import { findRevGroupReducer, revGroupReducer } from "../reducers/COAMasters/RevGroupReducer";
import { findRevTypeReducer, revTypeReducer } from "../reducers/COAMasters/RevTypeReducer";
import { findGewogReducer, gewogReducer } from "../reducers/LocationMaster/gewogsReducer";
import { findVillageReducer, villageReducer } from "../reducers/LocationMaster/villageReducer";
import { findRevSubTypeReducer, revSubTypeReducer } from "../reducers/COAMasters/RevSubTypeReducer";
import { findRevGlHeadReducer, revGlHeadReducer } from "../reducers/COAMasters/RevGLHeadReducer";
import { getSectoralListReducer, findSectoralListReducer } from "../reducers/OthersMaster/SectoralListReducer";
import { getStatusReducer, findStatusReducer } from "../reducers/OthersMaster/StatusMasterReducer";
import { findPrepaymentTypeReducer, getPrepaymentTypeReducer } from "../reducers/OthersMaster/PrepaymentTypeReducer";
import { findTransactionTypeReducer, getTransactionTypeReducer } from "../reducers/OthersMaster/TransactionTypeReducer";
import { findRewardPaymentReducer, rewardPaymentReducer } from "../reducers/COAMasters/RewardPaymentReducer";
import { findBankMasterReducer, bankMasterReducer } from "../reducers/COAMasters/BankMasterReducer";
import { findAgencyRrcoDzongkhagReducer, getAgencyRrcoDzongkhagReducer } from "../reducers/AgencyMaintenance/AgencyRrcoDzongkhagReducer";
import { findBankBranchReducer, bankBranchReducer } from "../reducers/COAMasters/BankBranchReducer";
import { findDrcBankAccountReducer, drcBankAccountReducer, findBankBranchAccountsReducer } from "../reducers/COAMasters/DRCBankAccountReducer";
import { findMenuTitleReducer, getMenuTitlesReducer } from "../reducers/Menu/MenuTitleReducer";
import { findMenuReducer, mainMenuReducer } from "../reducers/Menu/MainMenuReducer";
import { findSubMenuReducer, subMenuReducer } from "../reducers/Menu/SubMenuReducer";
import { findDisburseTypeReducer, disburseTypeReducer } from "../reducers/OthersMaster/DisburseTypeReducer";
import { disburseAllowReducer, findDisburseAllowReducer } from "../reducers/OthersMaster/DisburseAllowableReducer";
import { findExchangeRateReducer, getExchangeRateReducer } from "../reducers/OthersMaster/ExchangeRateReducer";
import { finRoleAssignMenuReducer, findUserRoleReducer, getUserRoleReducer } from "../reducers/User/UserRoleReducer";
import { findUserRoleDetailReducer, getUserRoleDetailReducer } from "../reducers/User/UserRoleDetailReducer";
import { findUserProfileReducer, getUserProfileReducer } from "../reducers/User/UserProfileReducer";
import { getAgencyRegReducer, findAgencyRegReducer, getAgencyTypeListReducer, findAgencyNameReducer } from "../reducers/AgencyRegistration/AgencyRegistrationReducer";
import { findCurrentUserDataReducer, findUserDataReducer, getUsersDataReducer } from "../reducers/User/UserDataReducer";
import { getAgencyMaintenanceReducer, findAgencyMaintenanceReducer } from "../reducers/AgencyMaintenance/AgencyMaintenanceReducer";
import { findOtherMasterService, getOtherMasterServices } from "../reducers/OthersMaster/ServiceReducer";
import { getAgencyContactPersonReducer, findAgencyContactPersonReducer } from "../reducers/AgencyMaintenance/AgencyContactPersonReducer";
import { getExternalSystemReducer, findExternalSystemReducer } from "../reducers/OthersMaster/ExternalSystemReducer";
import { findAgencyTypeChildrenReducer, findParentAgencyChildrenReducer, findAgencyChildrenReducer, findAgencyCoaServicesReducer, findSubAgencyOfParentAgency } from "../reducers/AgencyChildren/AgencyChildrenReducer";
import { findMajorGroupGlHeadReducer, findRevenueGroupGlHeadReducer, findRevenueTypeGlHeadReducer } from "../reducers/CoaMapping/CoaFilterReducer";
import { getCoaSectorMapReducer, findCoaSectorMapReducer } from "../reducers/CoaMapping/CoaSectorMapReducer";
import { getCoaRamisMapReducer, findCoaRamisMapReducer } from "../reducers/CoaMapping/CoaRamisMapReducer";
import { ramisMajorGroupReducer, findRamisMajorGroupReducer } from "../reducers/COARamis/RamisMajorGroupReducer";
import { ramisRevGroupReducer, findRamisRevGroupReducer } from "../reducers/COARamis/RamisRevGroupReducer";
import { ramisRevTypeReducer, findRamisRevTypeReducer } from "../reducers/COARamis/RamisRevTypeReducer";
import { ramisRevSubTypeReducer, findRamisRevSubTypeReducer } from "../reducers/COARamis/RamisSubTypeReducer";
import { ramisFunctionalAreaReducer, findRamisFunctionalAreaReducer } from "../reducers/COARamis/RamisFunctionalAreaReducer";
import { findRamisMajorGroupGlHeadReducer, findRamisRevenueGroupGlHeadReducer, findRamisRevenueTypeGlHeadReducer, findRamisRevenueSubTypeGlHeadReducer, findRamisFunctionalAreaGlHeadReducer } from "../reducers/COARamis/RamisGlHeadFilterReducer";
import { getCoaServiceMapReducer, findCoaServiceMapReducer } from "../reducers/CoaMapping/CoaServiceMapReducer";
import { getCoaAgencyMapReducer, findCoaAgencyMapReducer } from "../reducers/CoaMapping/CoaAgencyMapReducer";
import { getCoaBtcMapReducer, findCoaBtcMapReducer } from "../reducers/CoaMapping/CoaBtcMapReducer";
import { getCoaNonTaxGstMapReducer, findCoaNonTaxGstMapReducer } from "../reducers/CoaMapping/CoaGstMapReducer";
import { getCoaDisburseMapReducer, findCoaDisburseMapReducer } from "../reducers/CoaMapping/CoaDisburseMapReducer";
import { getCoaDrcBankMapReducer, findCoaDrcBankMapReducer } from "../reducers/CoaMapping/CoaDrcBankMapReducer";
import { getCoaTaxTypeProcessMapReducer, findCoaTaxTypeProcessMapReducer } from "../reducers/CoaMapping/CoaTaxTypeProcessReducer";
import { getCoaPrepaymentMapReducer, findCoaPrepaymentMapReducer } from "../reducers/CoaMapping/CoaPrepaymentReducer";
import { getCoaAppRequestReducer, findCoaAppRequestReducer, getCoaAppReviewReducer } from "../reducers/CoaApplication/CoaApplicationReducer";
import { findCidDetailReducer, findPaymentCidDetailReducer, findWorkPermitDetailReducer, findLcPlcDetailReducer, findPaymentTpnDetailReducer } from "../reducers/ThirdParty/ThirdPartyReducer";
import { getPaymentAdviceCountersReducer, findPaymentAdviceCounterReducer, paymentAdviceCounterResponseReducer } from "../reducers/PaymentAdvice/PaymentAdviceCounterReducer"
import { getPaymentAdviceSystemsReducer } from "../reducers/PaymentAdvice/PaymentAdviceSystemReducer"
import { findPaymentAdviceReducer, getGlheadsWithServicesReducer, getPayerTypeReducer } from "../reducers/PaymentAdvice/PaymentAdviceReducer";
import { getSelectedIdsPaymentAdvice, findGlHeadsRewardPaymentReducer, findPrepaymentBalanceReducer, findUserReceiptStockReducer, getCashDenominationsReducer, findIdentityTypePaymentAdviceReducer, getCounterPaymentResponseReducer } from "../reducers/CounterPayment/CounterPaymentReducer";
import { getReceiptMaintenanceReducer } from "../reducers/ReceiptMaintenance/ReceiptMaintenanceReducer";
import { createReconciliationSummary, getReconciliationExcelData, getReconciliationStagingData } from "../reducers/Reconciliation/ReconciliationReducer";
import { getAmendReducer, findAmendReducer } from "../reducers/ReceiptMaintenance/AmendReducer";
import { getUnmapPaymentReducer, findUnmapPaymentReducer } from "../reducers/ReceiptMaintenance/UnmapPaymentReducer";
import { getReversalReducer, findReversalReducer } from "../reducers/ReceiptMaintenance/ReversalReducer";
import { getReceiptMaintenanceReviewReducer } from "../reducers/ReceiptMaintenance/ReceiptMaintenanceReview/ReceiptMaintenanceReviewReducer";
import { getPaForGroupingReducer } from "../reducers/PaGrouping/PaGroupingReducer";
import { findReprintReducer } from "../reducers/ReceiptMaintenance/ReprintReducer";
import { findAmendPrintReducer } from "../reducers/ReceiptMaintenance/AmendPrintReducer";
import { receiptListReducer } from "../reducers/ReceiptList/ReceiptListReducer";
import { getReconciliationApprovalReducer, findReconciliationApprovalReducer } from "../reducers/Reconciliation/ReconciliationApprovalReducer";
import { getDepositSlipsReducer, getDepositReceiptsReducer, getDepositSlipResponseReducer, getDepositSlipAllUsersReducer } from "../reducers/DepositSlip/DepositSlipReducer";
import { getChequeBounceListReducer } from "../reducers/ChequeBounce/ChequeBounceReducer";
import { getDepositMaintenanceReducer } from "../reducers/DepositMaintenance/DepositMaintenanceReducer";
import { findDepositMaintenanceReducer } from "../reducers/DepositMaintenance/DepositMaintenanceReducer";
import { unmapDepositMaintenanceReducer } from "../reducers/DepositMaintenance/DepositMaintenanceReducer";
import { getSubAgencyListReducer } from "../reducers/AgencyRegistration/AgencyRegistrationReducer";
import { getAgencyTypesReducer, findAgencyTypeReducer } from "../reducers/AgencyMaster/AgencyTypeReducer";
import { departmentReducer, getDepartmentReducer } from "../reducers/AgencyMaster/DepartmentReducer";
import { getDivisionalRegionalOfficeReducer, divisionalRegionalOfficeReducer, findDivisionalRegionalOfficeReducer, AgencyListByDivisionIdReducer } from "../reducers/AgencyMaster/DivisionalRegionalOfficeReducer";
import { ministryOrganizationReducer, getMinistryOrganizationReducer, findMinistryOrganizationReducer } from "../reducers/AgencyMaster/MinistryOrganizationReducer";
import { findDepartmentReducer } from "../reducers/AgencyMaster/DepartmentReducer";
import { getBtcEditionsReducer, findBtcEditionReducer } from "../reducers/BtcMaster/EditionReducer";
import { getBtcSectionsReducer, findBtcSectionReducer } from "../reducers/BtcMaster/SectionReducer";
import { getBtcChaptersReducer, findBtcChapterReducer } from "../reducers/BtcMaster/ChapterReducer";
import { getBtcHeadingsReducer, findBtcHeadingReducer } from "../reducers/BtcMaster/HeadingReducer";
import { getBtcCommoditiesReducer, findBtcCommodityReducer, findBtcSectionCommodityReducer, findBtcChapterCommodityReducer } from "../reducers/BtcMaster/CommodityReducer";
import { getPaGroupingMaintenanceReducer, findPaGroupingMaintenanceReducer } from "../reducers/PaGroupingMaintenance/PaGroupingMaintenanceReducer";
import { getInboxNotificationReducer, findInboxNotificationReducer } from "../reducers/Inbox/InboxReducer";
import { getRmaFailedTransactionsReducer, findRmaFailedTransactionReducer, getRmaFailedTransactionResponseReducer } from "../reducers/RMAFailedTransaction/RMAFailedTransactionReducer";
import { getPrepaymentAdvicesReducer, findPrepaymentAdviceReducer, prepaymentAdviceResponseReducer } from "../reducers/PrepaymentAdvice/PrepaymentAdviceReducer";


//---------------------- PHASE-2 START----------------------//
import { cancelReceiptStockReducer, findDRCReceiptStockReducer, getDRCReceiptStockReducer, getDRCReceiptStockResponseReducer } from "../reducers_Phase_2/ReceiptStock/DRCReceiptStockReducer";
import { getAgencyReceiptStockReducer, findAgencyReceiptStockReducer, findAgencyUserReducer, findRrcoAgencyUserReducer, getAgencyReceiptStockResponseReducer } from "../reducers_Phase_2/ReceiptStock/AgencyReceiptStockReducer";
import { getRrcoReceiptStockReducer, findRrcoReceiptStockReducer, getRrcoReceiptStockResponseReducer } from "../reducers_Phase_2/ReceiptStock/RRCOReceiptStockReducer";
import { getReceiptAllocationRrcoReducer, getReceiptAllocationRrcoResponseReducer } from "../reducers_Phase_2/ReceiptStock/ReceiptAllocationToRRCOReducer";
import { getReceiptAllocationAgenciesReducer, getReceiptAllocationAgenciesResponseReducer } from "../reducers_Phase_2/ReceiptStock/ReceiptAllocationToAgenciesReducer";
import { vendorRegistrationListReducer, findVendorInformationReducer } from "../reducers_Phase_2/RefundAndDisbursement/VendorRegistrationReducer";
import { getAdvancePaymentsReducer, findAdvancePaymentReducer, getAdvancePaymentResponseReducer, findAdvancePaymentVendorReducer } from "../reducers_Phase_2/RefundAndDisbursement/AdvancePaymentReducer";
import { getPaymentVouchersReducer, getPaymentVoucherResponseReducer, findPaymentVoucherReducer } from "../reducers_Phase_2/RefundAndDisbursement/PaymentVoucherReducer";
import { getPaymentVoucherMaintainReducer, getPaymentVoucherMaintainResponseReducer, findPaymentVoucherMaintainReducer } from "../reducers_Phase_2/RefundAndDisbursement/PaymentVoucherMaintainReducer";
import { findRefundApplicationReducer, refundApplicationListReducer } from "../reducers_Phase_2/RefundAndDisbursement/RefundApplicationReducer";
import { getReplenishmentRequestsReducer, replenishmentResponseReducer, findReplenishmentReducer } from "../reducers_Phase_2/RefundAndDisbursement/ReplenishmentReducer";
import { disbursementVoucherReducer, findDisbursementVoucherReducer } from "../reducers_Phase_2/RefundAndDisbursement/DisbursementVoucherReducer";
import { getRewardsBeforeDepositReducer } from "../reducers_Phase_2/RefundAndDisbursement/RewardBeforeDepositReducer";
import { findRefundAmendReducer, refundAmendReducer } from "../reducers_Phase_2/RefundAndDisbursement/RefundAmendReducer";
import { getNettingOffRequestsReducer, findNettingOffRequestReducer, getNettingOffRequestResponseReducer, findNettingOffDataReducer, getSelectedNettingOffGlHeads } from "../reducers_Phase_2/NettingOff/NettingOffReducer";
import { findReconciliationMaintenanceReducer, reconciliationMaintenanceReducer } from "../reducers_Phase_2/ReconciliationMaintenance/ReconciliationMaintenanceReducer";
import { findWithdrawalDepositReducer, withdrawalDepositListReducer } from "../reducers_Phase_2/WithdrawalDeposit/WithdrawalDepositReducer";
import { advanceAdjustmentReducer, findAdvanceAdjustmentReducer } from "../reducers_Phase_2/AdvanceAdjustment/AdvanceAdjustmentReducer";
import { findReimbursementReducer, reimbursementReducer } from "../reducers_Phase_2/Reimbursement/ReimbursementReducer";
import { findRiskProfileEntityReducer, getRiskProfileEntitiesReducer } from "../reducers_Phase_2/RiskManagement/RiskProfileEntityReducer";
import { findRiskActionReducer, getRiskActionsReducer } from "../reducers_Phase_2/RiskManagement/RiskActionReducer";
import { findRiskTransactionProcessReducer, getRiskTransactionsProcessesReducer } from "../reducers_Phase_2/RiskManagement/RiskTransactionProcessReducer";
import { findRiskLevelReducer, getRiskLevelsReducer } from "../reducers_Phase_2/RiskManagement/RiskLevelReducer";
import { getRiskParametersIndicatorsReducer, findRiskParameterIndicatorReducer, getRiskParameterIndicatorTypesReducer, getRiskParametersByTransactionTypeReducer } from "../reducers_Phase_2/RiskManagement/RiskParameterIndicatorReducer";
import { getRiskAreasReducer, findRiskAreaReducer } from "../reducers_Phase_2/RiskManagement/RiskAreaReducer";
import { getRiskSubAreasReducer, findRiskSubAreaReducer } from "../reducers_Phase_2/RiskManagement/RiskSubAreaReducer";
import { getRiskLevelThresholdsReducer, findRiskLevelThresholdReducer } from "../reducers_Phase_2/RiskManagement/RiskLevelThresholdReducer";
import { getRiskParameterValuesReducer, findRiskParameterValueReducer } from "../reducers_Phase_2/RiskManagement/RiskParameterValueReducer";
import { getRiskRulesReducer, findRiskRuleReducer, getFilteredRiskRulesReducer } from "../reducers_Phase_2/RiskManagement/RiskRuleReducer";
import { getRiskProfilesReducer, findRiskProfileReducer } from "../reducers_Phase_2/RiskManagement/RiskProfileReducer";
import { getRiskAssessmentsReducer } from "../reducers_Phase_2/RiskManagement/RiskAssessmentReducer";
import { getAuditTypesReducer, findAuditTypeReducer } from "../reducers/OthersMaster/AuditTypeReducer";
import { getAuditSupportDataReducer, getAssignedAuditsReducer } from "../reducers_Phase_2/AuditManagement/AuditSupportReducer";
import { findAccountTypeReducer, getAccountTypeReducer } from "../reducers/OthersMaster/AccountTypeReducer";
import { getAuditPlansReducer, auditPlanResponseReducer, findAuditPlanReducer, getAuditPlanTeamRoles } from "../reducers_Phase_2/AuditManagement/AuditPlanReducer";
import { prelimAuditReportDataReducer } from "../reducers_Phase_2/AuditManagement/AuditReportReducer";
import { findTargetTypeReducer, getTargetTypeReducer } from "../reducers/OthersMaster/TargetTypeReducer";
import { findPreviousYearRevenueReducer, previousYearRevenueListReducer } from "../reducers_Phase_2/PreviousYearRevenue/PreviousYearRevenueReducer";
import { findRevenueForecastReducer, revenueForecastListReducer, revisionQuarterListReducer, targetTypeListReducer } from "../reducers_Phase_2/RevenueForecast/RevenueForecastReducer";
import { auditResponsesReducer, findActionByIdReducer, findAuditActionReducer, findAuditResponsesListReducer, findAuditResponsesReducer, findObservationResponseReview } from "../reducers_Phase_2/AuditManagement/AuditResponseReducer";
import { actionToBeTakenListReducer, findActionToBeTakenDetailsReducer, findActionToBeTakenReducer } from "../reducers_Phase_2/AuditManagement/ActionToBeTakenReducer";
import { findNotificationLevelReducer, getNotificationLevelReducer, getNotifyToReducer } from "../reducers_Phase_2/ArrearManagement/NotificationLevelReducer";
import { findNotificationConfigurationReducer, getNotificationLevelConfigurationReducer } from "../reducers_Phase_2/ArrearManagement/NotificationConfigurationReducer";
import { findReviseForecastReducer, reviseForecastListReducer } from "../reducers_Phase_2/RevenueForecast/ReviseForecastReducer";
import { findReceiptVoucherReducer, receiptVouchersReducer } from "../reducers_Phase_2/ReceiptVoucher/ReceiptVoucherReducer";
import { findDisburseThresholdReducer, getDisburseThresholdsReducer } from "../reducers/OthersMaster/DisburseThresholdReducer";
import { findWriteOffReducer, writeOffListReducer, writeOffReasonReducer } from "../reducers_Phase_2/WriteOff/WriteOffReducer";
import { findGroupDepositSlipReducer, groupDepositSlipListReducer } from "../reducers_Phase_2/GroupDepositSlip/GroupDepositSlipReducer";

//---------------------- PHASE-2 END----------------------//

const RootReducer = combineReducers({
  menuTitles: getMenuTitlesReducer,
  findMenuTitle: findMenuTitleReducer,
  mainMenus: mainMenuReducer,
  findMainMenu: findMenuReducer,
  subMenus: subMenuReducer,
  findSubMenu: findSubMenuReducer,
  user: userReducer,
  regionalOffice: getRegionalOffceReducer,
  findOffice: findRegionalOffceReducer,
  dzongkhages: dzongkhageReducer,
  findDzongkhage: finddzongkhageReducer,
  dungkhags: dungkhagReducer,
  findDungkhag: findDungkhagReducer,
  gewogs: gewogReducer,
  findGewog: findGewogReducer,
  villages: villageReducer,
  findvillage: findVillageReducer,
  coaGroups: getCOAGroupsReducer,
  findCOAGroup: findCOAGroupReducer,
  modeOfPayments: getModeOfPaymentReducer,
  findModeOfPayment: findModeOfPaymentReducer,
  businessSectors: getBusinessSectorReducer,
  findBusinessSector: findBusinessSectorReducer,
  financialYears: getFinancialYearReducer,
  findFinancialYear: findFinancialYearReducer,
  accountTypes: getAccountTypeReducer,
  findAccountType: findAccountTypeReducer,
  targetTypes: getTargetTypeReducer,
  findTargetType: findTargetTypeReducer,
  countries: getCountryReducer,
  findCountry: findCountryReducer,
  configOptions: getConfigOptionsReducer,
  findConfigOption: findConfigOptionReducer,
  currencies: getCurrenciesReducer,
  findCurrency: findCurrencyReducer,
  taxTypes: getTaxTypeReducer,
  findTaxType: findTaxTypeReducer,
  getServices: getOtherMasterServices,
  findService: findOtherMasterService,
  taxProcesses: getTaxProcessReducer,
  findTaxProcess: findTaxProcessReducer,
  paymentMethods: getPaymentMethodReducer,
  findPaymentMethod: findPaymentMethodReducer,
  majorGroups: majorGroupReducer,
  findMajorGroup: findMajorGroupReducer,
  revGroups: revGroupReducer,
  findRevGroup: findRevGroupReducer,
  revTypes: revTypeReducer,
  findRevType: findRevTypeReducer,
  revSubTypes: revSubTypeReducer,
  findRevSubType: findRevSubTypeReducer,
  revGlHeads: revGlHeadReducer,
  findRevGlHead: findRevGlHeadReducer,
  sectoralList: getSectoralListReducer,
  singleSector: findSectoralListReducer,
  statusMasters: getStatusReducer,
  findStatusMaster: findStatusReducer,
  prepaymentTypes: getPrepaymentTypeReducer,
  findPrepaymentType: findPrepaymentTypeReducer,
  transactionTypes: getTransactionTypeReducer,
  findTransactionType: findTransactionTypeReducer,
  rewardPayments: rewardPaymentReducer,
  findRewardPayment: findRewardPaymentReducer,
  bankMasters: bankMasterReducer,
  findBankMaster: findBankMasterReducer,
  agencyRrcoDzongkhags: getAgencyRrcoDzongkhagReducer,
  findAgencyRrcoDzongkhag: findAgencyRrcoDzongkhagReducer,
  bankBranches: bankBranchReducer,
  findBankBranch: findBankBranchReducer,
  drcBankAccounts: drcBankAccountReducer,
  findDrcBankAccount: findDrcBankAccountReducer,
  findBankBranchAccounts: findBankBranchAccountsReducer,
  disburseTypes: disburseTypeReducer,
  findDisburseType: findDisburseTypeReducer,
  disburseAllowables: disburseAllowReducer,
  findDisburseAllowable: findDisburseAllowReducer,
  exchangeRates: getExchangeRateReducer,
  findExchangeRate: findExchangeRateReducer,
  userRoles: getUserRoleReducer,
  findUserRole: findUserRoleReducer,
  findRolebaseAssignMenuList: finRoleAssignMenuReducer,
  userRoleDetails: getUserRoleDetailReducer,
  findUserRoleDetail: findUserRoleDetailReducer,
  userProfiles: getUserProfileReducer,
  findUserProfile: findUserProfileReducer,
  agencyRegs: getAgencyRegReducer,
  findAgencyReg: findAgencyRegReducer,
  agencyTypeList: getAgencyTypeListReducer,
  subAgencyList: getSubAgencyListReducer,
  findCidDetail: findCidDetailReducer,
  findPaymentCidDetail: findPaymentCidDetailReducer,
  findWorkPermitDetail: findWorkPermitDetailReducer,
  findLcPlcDetail: findLcPlcDetailReducer,
  findPaymentTpnDetail: findPaymentTpnDetailReducer,
  findAgencyName: findAgencyNameReducer,
  usersData: getUsersDataReducer,
  findUserData: findUserDataReducer,
  findCurrentUserData: findCurrentUserDataReducer,
  agencyMaintenances: getAgencyMaintenanceReducer,
  findAgencyMaintenance: findAgencyMaintenanceReducer,
  agencyContactPersons: getAgencyContactPersonReducer,
  findAgencyContactPerson: findAgencyContactPersonReducer,
  externalSystems: getExternalSystemReducer,
  findExternalSystem: findExternalSystemReducer,
  findAgencyTypeChildren: findAgencyTypeChildrenReducer,
  findParentAgencyChildren: findParentAgencyChildrenReducer,
  findAgencyChildren: findAgencyChildrenReducer,
  findAgencyCoaServices: findAgencyCoaServicesReducer,
  findMajorGroupGlHeads: findMajorGroupGlHeadReducer,
  findRevenueGroupGlHeads: findRevenueGroupGlHeadReducer,
  findRevenueTypeGlHeads: findRevenueTypeGlHeadReducer,
  coaSectorMaps: getCoaSectorMapReducer,
  findCoaSectorMap: findCoaSectorMapReducer,
  coaRamisMaps: getCoaRamisMapReducer,
  findCoaRamisMap: findCoaRamisMapReducer,
  ramisMajorGroups: ramisMajorGroupReducer,
  findRamisMajorGroup: findRamisMajorGroupReducer,
  ramisRevGroups: ramisRevGroupReducer,
  findRamisRevGroup: findRamisRevGroupReducer,
  ramisRevTypes: ramisRevTypeReducer,
  findRamisRevType: findRamisRevTypeReducer,
  ramisRevSubTypes: ramisRevSubTypeReducer,
  findRamisRevSubType: findRamisRevSubTypeReducer,
  ramisFunctionAreas: ramisFunctionalAreaReducer,
  findRamisFunctionArea: findRamisFunctionalAreaReducer,
  findRamisMajorGroupGlHeads: findRamisMajorGroupGlHeadReducer,
  findRamisRevenueGroupGlHeads: findRamisRevenueGroupGlHeadReducer,
  findRamisRevenueTypeGlHeads: findRamisRevenueTypeGlHeadReducer,
  findRamisRevenueSubTypeGlHeads: findRamisRevenueSubTypeGlHeadReducer,
  findRamisFunctionalAreaGlHeads: findRamisFunctionalAreaGlHeadReducer,
  coaServiceMaps: getCoaServiceMapReducer,
  findCoaServiceMap: findCoaServiceMapReducer,
  coaAgencyMaps: getCoaAgencyMapReducer,
  findCoaAgencyMap: findCoaAgencyMapReducer,
  coaBtcMaps: getCoaBtcMapReducer,
  findCoaBtcMap: findCoaBtcMapReducer,
  coaNonTaxGstMaps: getCoaNonTaxGstMapReducer,
  findcoaNonTaxGstMap: findCoaNonTaxGstMapReducer,
  coaDisburseMaps: getCoaDisburseMapReducer,
  findCoaDisburseMap: findCoaDisburseMapReducer,
  coaDrcBankMaps: getCoaDrcBankMapReducer,
  findCoaDrcBankMap: findCoaDrcBankMapReducer,
  coaTaxTypeProcessMaps: getCoaTaxTypeProcessMapReducer,
  findcoaTaxTypeProcessMap: findCoaTaxTypeProcessMapReducer,
  coaPrepaymentMaps: getCoaPrepaymentMapReducer,
  findCoaPrepaymentMap: findCoaPrepaymentMapReducer,
  coaAppRequests: getCoaAppRequestReducer,
  findCoaAppRequest: findCoaAppRequestReducer,
  coaAppReviews: getCoaAppReviewReducer,
  paymentAdviceCounters: getPaymentAdviceCountersReducer,
  findPaymentAdviceCounter: findPaymentAdviceCounterReducer,
  paymentAdviceCounterResponse: paymentAdviceCounterResponseReducer,
  paymentAdviceSystems: getPaymentAdviceSystemsReducer,
  findPaymentAdvice: findPaymentAdviceReducer,
  identityTypePaymentAdvice: findIdentityTypePaymentAdviceReducer,
  glHeadsWithServices: getGlheadsWithServicesReducer,
  payerTypes: getPayerTypeReducer,
  findGlHeadsRewardPayment: findGlHeadsRewardPaymentReducer,
  findPrepaymentBalance: findPrepaymentBalanceReducer,
  findUserReceiptStock: findUserReceiptStockReducer,
  cashDenominations: getCashDenominationsReducer,
  counterPaymentResponse: getCounterPaymentResponseReducer,
  selectedIdsPaymentAdvice: getSelectedIdsPaymentAdvice,
  reconciliationExcelData: getReconciliationExcelData,
  reconciliationSummary: createReconciliationSummary,
  reconciliationStagingData: getReconciliationStagingData,
  receiptMaintenances: getReceiptMaintenanceReducer,
  receiptLists: receiptListReducer,
  receiptAmends: getAmendReducer,
  findReceiptAmend: findAmendReducer,
  receiptUnmapPayments: getUnmapPaymentReducer,
  findReceiptUnmapPayment: findUnmapPaymentReducer,
  receiptReversals: getReversalReducer,
  findReceiptReversal: findReversalReducer,
  receiptMaintenanceReview: getReceiptMaintenanceReviewReducer,
  findReceiptReprint: findReprintReducer,
  findReceiptAmendPrint: findAmendPrintReducer,
  paForGrouping: getPaForGroupingReducer,
  depositSlips: getDepositSlipsReducer,
  depositReceipts: getDepositReceiptsReducer,
  reconciliationApprovals: getReconciliationApprovalReducer,
  findReconciliationApproval: findReconciliationApprovalReducer,
  depositSlipResponse: getDepositSlipResponseReducer,
  depositSlipAllUsers: getDepositSlipAllUsersReducer,
  chequeBounceList: getChequeBounceListReducer,
  depositMaintenances: getDepositMaintenanceReducer,
  findDepositMaintenance: findDepositMaintenanceReducer,
  unmapDepositMaintenance: unmapDepositMaintenanceReducer,

  multipleSubAgencyList: findSubAgencyOfParentAgency,

  agencyTypes: getAgencyTypesReducer,
  findAgencyType: findAgencyTypeReducer,

  ministryMaster: ministryOrganizationReducer,
  ministryByAgencyId: getMinistryOrganizationReducer,
  singleMinistryMaster: findMinistryOrganizationReducer,

  departmentMaster: departmentReducer,
  departmentById: getDepartmentReducer,
  singleDepartmentMaster: findDepartmentReducer,

  divisionalMaster: divisionalRegionalOfficeReducer,
  divisionalMasterById: getDivisionalRegionalOfficeReducer,
  singleDivisionMaster: findDivisionalRegionalOfficeReducer,
  agencyListByDivisionId: AgencyListByDivisionIdReducer,

  btcEditions: getBtcEditionsReducer,
  findBtcEdition: findBtcEditionReducer,
  btcSections: getBtcSectionsReducer,
  findBtcSection: findBtcSectionReducer,
  btcChapters: getBtcChaptersReducer,
  findBtcChapter: findBtcChapterReducer,
  btcHeadings: getBtcHeadingsReducer,
  findBtcHeading: findBtcHeadingReducer,
  btcCommodities: getBtcCommoditiesReducer,
  findBtcCommodity: findBtcCommodityReducer,
  findBtcSectionCommodity: findBtcSectionCommodityReducer,
  findBtcChapterCommodity: findBtcChapterCommodityReducer,

  paGroupingMaintenances: getPaGroupingMaintenanceReducer,
  findPaGroupingMaintenance: findPaGroupingMaintenanceReducer,

  inboxNotifications: getInboxNotificationReducer,
  findInboxNotification: findInboxNotificationReducer,
  rmaFailedTransactions: getRmaFailedTransactionsReducer,
  findRmaFailedTransaction: findRmaFailedTransactionReducer,
  rmaFailedTransactionResponse: getRmaFailedTransactionResponseReducer,
  prepaymentAdvices: getPrepaymentAdvicesReducer,
  findPrepaymentAdvice: findPrepaymentAdviceReducer,
  prepaymentAdviceResponse: prepaymentAdviceResponseReducer,

  //---------------------- PHASE-2 START----------------------//
  // Receipt Stock
  findAgencyUser: findAgencyUserReducer,
  findRrcoAgencyUser: findRrcoAgencyUserReducer,
  drcReceiptStocks: getDRCReceiptStockReducer,
  cancelReceiptStocks: cancelReceiptStockReducer,
  findDRCReceiptStock: findDRCReceiptStockReducer,
  drcReceiptStockResponse: getDRCReceiptStockResponseReducer,
  receiptAllocationRrcos: getReceiptAllocationRrcoReducer,
  receiptAllocationRrcoResponse: getReceiptAllocationRrcoResponseReducer,
  rrcoReceiptStocks: getRrcoReceiptStockReducer,
  findRrcoReceiptStock: findRrcoReceiptStockReducer,
  rrcoReceiptStockResponse: getRrcoReceiptStockResponseReducer,
  receiptAllocationAgencies: getReceiptAllocationAgenciesReducer,
  receiptAllocationAgenciesResponse:
    getReceiptAllocationAgenciesResponseReducer,
  agencyReceiptStocks: getAgencyReceiptStockReducer,
  findAgencyReceiptStock: findAgencyReceiptStockReducer,
  agencyReceiptStockResponse: getAgencyReceiptStockResponseReducer,
  // Refund & Disbursement
  vendorRegistrationList: vendorRegistrationListReducer,
  findVendorInformation: findVendorInformationReducer,
  advancePayments: getAdvancePaymentsReducer,
  advancePaymentResponse: getAdvancePaymentResponseReducer,
  findAdvancePayment: findAdvancePaymentReducer,
  findAdvancePaymentVendor: findAdvancePaymentVendorReducer,
  paymentVouchers: getPaymentVouchersReducer,
  paymentVoucherResponse: getPaymentVoucherResponseReducer,
  findPaymentVoucher: findPaymentVoucherReducer,
  paymentVouchersMaintain: getPaymentVoucherMaintainReducer,
  paymentVoucherMaintainResponse: getPaymentVoucherMaintainResponseReducer,
  findPaymentVoucherMaintain: findPaymentVoucherMaintainReducer,
  refundApplicationList: refundApplicationListReducer,
  findRefundApplication: findRefundApplicationReducer,
  refundAmendList: refundAmendReducer,
  findRefundAmend: findRefundAmendReducer,
  replenishmentRequests: getReplenishmentRequestsReducer,
  replenishmentResponse: replenishmentResponseReducer,
  findReplenishment: findReplenishmentReducer,
  disbursementVoucherList: disbursementVoucherReducer,
  findDisbursementVoucher: findDisbursementVoucherReducer,
  rewardsBeforeDeposit: getRewardsBeforeDepositReducer,
  reconciliationMaintenanceList: reconciliationMaintenanceReducer,
  reconciliationMaintenance: findReconciliationMaintenanceReducer,
  nettingOffRequests: getNettingOffRequestsReducer,
  nettingOffRequestResponse: getNettingOffRequestResponseReducer,
  findNettingOffRequest: findNettingOffRequestReducer,
  withdrawalDepositList: withdrawalDepositListReducer,
  findWithdrawalDeposit: findWithdrawalDepositReducer,
  findNettingOffData: findNettingOffDataReducer,
  selectedNettingOffGlHeads: getSelectedNettingOffGlHeads,
  advanceAdjustmentList: advanceAdjustmentReducer,
  findAdvanceAdjustment: findAdvanceAdjustmentReducer,
  reimbursementList: reimbursementReducer,
  findReimbursement: findReimbursementReducer,
  // Risk management
  riskProfileEntities: getRiskProfileEntitiesReducer,
  findRiskProfileEntity: findRiskProfileEntityReducer,
  riskActions: getRiskActionsReducer,
  findRiskAction: findRiskActionReducer,
  riskTransactionsProcesses: getRiskTransactionsProcessesReducer,
  findRiskTransactionProcess: findRiskTransactionProcessReducer,
  riskLevels: getRiskLevelsReducer,
  findRiskLevel: findRiskLevelReducer,
  riskParametersIndicators: getRiskParametersIndicatorsReducer,
  findRiskParameterIndicator: findRiskParameterIndicatorReducer,
  riskParameterIndicatorTypes: getRiskParameterIndicatorTypesReducer,
  riskParametersByTransactionType: getRiskParametersByTransactionTypeReducer,
  riskAreas: getRiskAreasReducer,
  findRiskArea: findRiskAreaReducer,
  riskSubAreas: getRiskSubAreasReducer,
  findRiskSubArea: findRiskSubAreaReducer,
  riskLevelThresholds: getRiskLevelThresholdsReducer,
  findRiskLevelThreshold: findRiskLevelThresholdReducer,
  riskParameterValues: getRiskParameterValuesReducer,
  findRiskParameterValue: findRiskParameterValueReducer,
  riskRules: getRiskRulesReducer,
  findRiskRule: findRiskRuleReducer,
  filteredRiskRules: getFilteredRiskRulesReducer,
  riskProfiles: getRiskProfilesReducer,
  findRiskProfile: findRiskProfileReducer,
  riskAssessments: getRiskAssessmentsReducer,
  // Audit management
  auditTypes: getAuditTypesReducer,
  findAuditType: findAuditTypeReducer,
  auditSupportData: getAuditSupportDataReducer,
  auditPlans: getAuditPlansReducer,
  auditPlanResponse: auditPlanResponseReducer,
  findAuditPlan: findAuditPlanReducer,
  auditPlanTeamRoles: getAuditPlanTeamRoles,
  auditResponses: auditResponsesReducer,
  findAuditResponse: findAuditResponsesReducer,
  findAuditResponsesList: findAuditResponsesListReducer,
  findAuditAction: findAuditActionReducer,
  findActionById: findActionByIdReducer,
  findObservationResponse: findObservationResponseReview,
  actionToBeTakenList: actionToBeTakenListReducer,
  findActionToBeTakenDetails: findActionToBeTakenDetailsReducer,
  findActionToBeTaken: findActionToBeTakenReducer,
  assignedAudits: getAssignedAuditsReducer,
  prelimAuditReportData: prelimAuditReportDataReducer,
  previousYearRevenueList: previousYearRevenueListReducer,
  findPreviousYearRevenue: findPreviousYearRevenueReducer,
  revenueForecastList: revenueForecastListReducer,
  findRevenueForecast: findRevenueForecastReducer,
  forecastTargetTypes: targetTypeListReducer,
  quarterList: revisionQuarterListReducer,
  reviseForecastList: reviseForecastListReducer,
  findReviseForecast: findReviseForecastReducer,
  // Arrear management
  notificationLevels: getNotificationLevelReducer,
  findNotificationLevel: findNotificationLevelReducer,
  notificationConfigs: getNotificationLevelConfigurationReducer,
  findNotificationConfig: findNotificationConfigurationReducer,
  notifyToList: getNotifyToReducer,
  disburseThresholds: getDisburseThresholdsReducer,
  findDisburseThreshold: findDisburseThresholdReducer,
  receiptVouchers: receiptVouchersReducer,
  findReceiptVoucher: findReceiptVoucherReducer,
  writeOffList: writeOffListReducer,
  writeOffReasons: writeOffReasonReducer,
  findWriteOff: findWriteOffReducer,
  groupDepositSlipList: groupDepositSlipListReducer,
  findGroupDepositSlip: findGroupDepositSlipReducer,

  //---------------------- PHASE-2 END----------------------//
});


const findUser =
  secureLocalStorage.getItem(`uinfo`)
    ?
    JSON.parse(secureLocalStorage.getItem(`uinfo`))
    :
    null;



let user;

if (findUser) {
  user = {
    loading: false,
    isAuthenticated: findUser?.tokenDto?.accessToken !== "" ? true : false,
    userInfo: findUser?.userInfo,
    user: findUser?.tokenDto,
    menu: findUser?.menuResponseDtoList,
    userAgency: findUser?.agenciesModel,
    userRegionalOffice: findUser?.regionalOffice,
    message: null
  };
}

let initialState = {
  user: user,
};

const middleware = [thunk];

const store = createStore(
  RootReducer,
  initialState,
  applyMiddleware(...middleware)
);

export default store;
