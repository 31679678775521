import React from "react";
import { Breadcrumb } from "react-bootstrap";

const CommonSubHeader = ({item,title, subItem }) => (
  <div className="breadcrumb-header justify-content-between">
    <div className="left-content">
      <span className="main-content-title mg-b-0 mg-b-lg-1">{title}</span>
    </div>
    <div className="justify-content-center mt-2">
      <Breadcrumb className="breadcrumb">
      <>
            <Breadcrumb.Item className="breadcrumb-item" href="#">
              {item}
            </Breadcrumb.Item>
            { subItem && <Breadcrumb.Item
              className="breadcrumb-item text-info"
              active
              aria-current="page"
            >
             {subItem}
            </Breadcrumb.Item>}
          </>
      </Breadcrumb>
    </div>
  </div>
);

export default CommonSubHeader;
