import CommonToast from "../CommonComponents/CommonToast/CommonToast";
import axiosRequest from "../services/Interceptor/axiosRequest";
const TIMEOUT_DURATION = 600000; // 10 minutes in milliseconds

export const excelDownload = async (url, fileName, service, setExcelLoader) => {
  try {
    setExcelLoader(true);
    const response = await axiosRequest.get(`/${service}/${url}`, {
      responseType: "blob",
      timeout: TIMEOUT_DURATION 
    });

    if (response.status === 200) {
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}.xlsx`;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      setExcelLoader(false);
    }
  } catch (error) {
    if (error.response?.status === 409) {
      CommonToast("conflict", "No Data Found...");
    } else {
      console.error("An error occurred:", error);
    }
    setExcelLoader(false);
  }
};
