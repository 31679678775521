import axios from "axios";
import { Navigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import CommonToast from "../../CommonComponents/CommonToast/CommonToast";

import {
  USER_SUCCESS,
  LOGOUT_SUCCESS,
} from "../../redux/constants/userConstants";

import store from "../../redux/store/store";

// Request Config
const axiosRequest = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

let hasShownToast = false;

// Axios Request
axiosRequest.interceptors.request.use((config) => {
  const userInfo = JSON.parse(secureLocalStorage.getItem(`uinfo`));

  if (!userInfo?.tokenDto?.accessToken) {
    <Navigate to="/authentication/login" />;
  } else {
    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    }
    config["Access-Control-Allow-Origin"] = "*";
    config.headers.Authorization = `Bearer ${userInfo?.tokenDto?.accessToken}`;
    return config;
  }
});

// Axios Response
axiosRequest.interceptors.response.use(
  async function (response) {
    return response;
  },
  async function (error) {
    const userInfo = JSON.parse(secureLocalStorage.getItem(`uinfo`));

    if (error?.response?.status === 401) {
      if (!hasShownToast) {
        CommonToast("warn", "You have been automatically logged out for security reasons. Please login again!");
        hasShownToast = true;
      }
      store.dispatch({
        type: LOGOUT_SUCCESS,
      });
      secureLocalStorage.removeItem("uinfo");
    }

    if (error?.response?.status === 422) {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_AUTH_URL}/auth/refreshToken`,
          { refreshToken: userInfo?.tokenDto?.refreshToken },
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          }
        );
        if (res?.status === 200) {
          let changeStore = JSON.parse(secureLocalStorage.getItem(`uinfo`));
          changeStore.tokenDto = res?.data;
          store.dispatch({
            type: USER_SUCCESS,
            payload: res?.data,
            menuPermission: changeStore?.menuResponseDtoList,
            userAgency: changeStore?.agenciesModel,
            userRegionalOffice: changeStore?.regionalOffice
          });
          secureLocalStorage.setItem(`uinfo`, JSON.stringify(changeStore));
          window.location.reload()
          // CommonToast("warn", "Access validation, Try Again!");
        }
      } catch (err) {
        if (err.response.status === 404 || err.response.status === 401) {
          store.dispatch({
            type: LOGOUT_SUCCESS,
          });
          if (!hasShownToast) {
            CommonToast("warn", "Your session has expired!");
            hasShownToast = true;
          }
          secureLocalStorage.removeItem("uinfo");
        }
      }
    }

    return Promise.reject(error);
  }
);

export default axiosRequest;
