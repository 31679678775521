import {
    PA_GROUPING_MAINTENANCE_REQUEST,
    PA_GROUPING_MAINTENANCE_SUCCESS,
    PA_GROUPING_MAINTENANCE_FAIL,

    FIND_PA_GROUPING_MAINTENANCE_REQUEST,
    FIND_PA_GROUPING_MAINTENANCE_SUCCESS,
    FIND_PA_GROUPING_MAINTENANCE_FAIL,

    PA_GROUPING_MAINTENANCE_CANCEL_REQUEST,
    PA_GROUPING_MAINTENANCE_CANCEL_SUCCESS,
    PA_GROUPING_MAINTENANCE_CANCEL_FAIL,

    PA_GROUPING_MAINTENANCE_UNMAP_REQUEST,
    PA_GROUPING_MAINTENANCE_UNMAP_SUCCESS,
    PA_GROUPING_MAINTENANCE_UNMAP_FAIL,

    PA_GROUPING_MAINTENANCE_CLEAR_ERROR,

} from "../../constants/PaGroupingMaintenance/PaGroupingMaintenanceConstant";

export const getPaGroupingMaintenanceReducer = (state = { paGroupingMaintenances: [] }, action) => {
    switch (action.type) {
        case PA_GROUPING_MAINTENANCE_REQUEST:
        case PA_GROUPING_MAINTENANCE_CANCEL_REQUEST:
        case PA_GROUPING_MAINTENANCE_UNMAP_REQUEST:
            return {
                ...state,
                paGroupingMaintenances: [],
                loading: true,
                status: null,
                message: null,
                error: null,
            };

        case PA_GROUPING_MAINTENANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                paGroupingMaintenances: action.payload,
                totalData: action.totalData,
                message: null,
                status: null,
            };


        case PA_GROUPING_MAINTENANCE_CANCEL_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
            };

        case PA_GROUPING_MAINTENANCE_UNMAP_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
                paGroupingMaintenances: action.payload,
            };

        case PA_GROUPING_MAINTENANCE_FAIL:
        case PA_GROUPING_MAINTENANCE_CANCEL_FAIL:
        case PA_GROUPING_MAINTENANCE_UNMAP_FAIL:
            return {
                ...state,
                loading: false,
                paGroupingMaintenances: [...state.paGroupingMaintenances],
                error: action.payload,
                message: action.message,
                status: action.status,
            };

        case "EMPTY_PA_GROUPING_MAINTENANCE":
            return {
                ...state,
                paGroupingMaintenances: [],
            };

        case PA_GROUPING_MAINTENANCE_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};






export const findPaGroupingMaintenanceReducer = (state = { paGroupingMaintenance: {} }, action) => {
    switch (action.type) {
        case FIND_PA_GROUPING_MAINTENANCE_REQUEST:
            return {
                loading: true,
                paGroupingMaintenance: {},
            };
        case FIND_PA_GROUPING_MAINTENANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                paGroupingMaintenance: action.payload,
            };

        case FIND_PA_GROUPING_MAINTENANCE_FAIL:
            return {
                ...state,
                loading: false,
                paGroupingMaintenance: {},
                error: action.payload,
            };
        case "EMPTY_PA_GROUPING_MAINTENANCE":
            return {
                ...state,
                paGroupingMaintenance: {},
            };

        case PA_GROUPING_MAINTENANCE_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};




