import axiosRequest from "../../../services/Interceptor/axiosRequest";
import {
  CREATE_FINANCIAL_YEAR_FAIL,
  CREATE_FINANCIAL_YEAR_REQUEST,
  CREATE_FINANCIAL_YEAR_SUCCESS,
  DELETE_FINANCIAL_YEAR_FAIL,
  DELETE_FINANCIAL_YEAR_REQUEST,
  DELETE_FINANCIAL_YEAR_SUCCESS,
  FINANCIAL_YEAR_CLEAR_ERROR,
  FINANCIAL_YEAR_FAIL,
  FINANCIAL_YEAR_REQUEST,
  FINANCIAL_YEAR_SUCCESS,
  FIND_FINANCIAL_YEAR_FAIL,
  FIND_FINANCIAL_YEAR_REQUEST,
  FIND_FINANCIAL_YEAR_SUCCESS,
  UPDATE_FINANCIAL_YEAR_FAIL,
  UPDATE_FINANCIAL_YEAR_REQUEST,
  UPDATE_FINANCIAL_YEAR_SUCCESS,
} from "../../constants/OthersMaster/FinancialYearsConstant";

// Create
export const createFinancialYear = (data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_FINANCIAL_YEAR_REQUEST });
    const res = await axiosRequest.post(
      `/${process.env.REACT_APP_CORE_URL}/financial-year/create`,
      data
    );

    if (res?.data?.statusCode !== 409) {
      dispatch({
        type: CREATE_FINANCIAL_YEAR_SUCCESS,
        payload: res.data.content,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } else {
      dispatch({
        type: CREATE_FINANCIAL_YEAR_FAIL, //For conflict
        message: res.data.message,
        status: res.data.statusCode,
      });
    }
  } catch (error) {
    dispatch({
      type: CREATE_FINANCIAL_YEAR_FAIL,
      payload: error,
    });
  }
};

// Get All
export const getFinancialYears = () => async (dispatch) => {
  try {
    dispatch({ type: FINANCIAL_YEAR_REQUEST });
    const { data } = await axiosRequest.get(
      `/${process.env.REACT_APP_CORE_URL}/financial-year/all`
    );
    dispatch({ type: FINANCIAL_YEAR_SUCCESS, payload: data.content });
  } catch (error) {
    dispatch({
      type: FINANCIAL_YEAR_FAIL,
      payload: error,
    });
  }
};

// Find
export const findFinancialYear = (id) => async (dispatch) => {
  try {
    dispatch({ type: FIND_FINANCIAL_YEAR_REQUEST });
    const res = await axiosRequest.get(
      `/${process.env.REACT_APP_CORE_URL}/financial-year/${id}`
    );
    dispatch({ type: FIND_FINANCIAL_YEAR_SUCCESS, payload: res.data.content });
  } catch (error) {
    dispatch({
      type: FIND_FINANCIAL_YEAR_FAIL,
      payload: error,
    });
  }
};

// Update
export const updateFinancialYear = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_FINANCIAL_YEAR_REQUEST });
    const res = await axiosRequest.put(
      `/${process.env.REACT_APP_CORE_URL}/financial-year/${id}`,
      data
    );

    if (res?.data?.statusCode !== 409) {
      dispatch({
        type: UPDATE_FINANCIAL_YEAR_SUCCESS,
        payload: res.data.content,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } else {
      dispatch({
        type: UPDATE_FINANCIAL_YEAR_FAIL, //For conflict
        message: res.data.message,
        status: res.data.statusCode,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_FINANCIAL_YEAR_FAIL,
      payload: error,
    });
  }
};
export const updateFinancialYearStatus = (status, id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_FINANCIAL_YEAR_REQUEST });
    const res = await axiosRequest.put(
      `/${process.env.REACT_APP_CORE_URL}/financial-year/status/${id}/${status}`);

    if (res?.data?.statusCode !== 409) {
      dispatch({
        type: UPDATE_FINANCIAL_YEAR_SUCCESS,
        payload: res.data.content,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } else {
      dispatch({
        type: UPDATE_FINANCIAL_YEAR_FAIL, //For conflict
        message: res.data.message,
        status: res.data.statusCode,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_FINANCIAL_YEAR_FAIL,
      payload: error,
    });
  }
};

// Delete
export const deleteFinancialYear = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_FINANCIAL_YEAR_REQUEST });
    const res = await axiosRequest.put(
      `/${process.env.REACT_APP_CORE_URL}/financial-year/delete/${id}`,
      formData
    );

    if (
      res?.data?.statusCode !== 409 &&
      res?.data?.content?.isDelete === true
    ) {
      dispatch({
        type: DELETE_FINANCIAL_YEAR_SUCCESS,
        payload: id,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } else if (
      res?.data?.statusCode !== 409 &&
      res?.data?.content?.isDelete === false
    ) {
      dispatch({
        type: "FUTURE_DELETE_FINANCIAL_YEAR_SUCCESS",
        payload: res?.data?.content,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } else {
      dispatch({
        type: DELETE_FINANCIAL_YEAR_FAIL,
        message: res.data.message,
        status: res.data.statusCode,
      });
    }
  } catch (error) {
    dispatch({
      type: DELETE_FINANCIAL_YEAR_FAIL,
      payload: error,
    });
  }
};

export const clearError = () => async (dispatch) => {
  dispatch({ type: FINANCIAL_YEAR_CLEAR_ERROR });
};
