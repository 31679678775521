import React from 'react';


const ReportHeader = () => {
  return (
    <div className="text-center mb-5 pos-relative">
      <div style={{ maxWidth: "120px", position:"absolute", top:"50%", transform:"translateY(-50%)" }}>
        <img
          src={require("../../../assets/img/brand/logo.png")}
          alt=""
        />
      </div>
      <div className="text-center py-3">
        <h3 className="fw-bold">
          Royal Government of Bhutan
        </h3>
        <h6 style={{ fontSize: "15px" }}>
          Ministry of Finance
        </h6>
        <h6 style={{ fontSize: "15px" }}>
          Department of Revenue and Customs
        </h6>
      </div>
    </div>
  );
};

export default ReportHeader;