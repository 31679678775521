import {
    CREATE_REVENUE_FORECAST_REQUEST,
    CREATE_REVENUE_FORECAST_SUCCESS,
    CREATE_REVENUE_FORECAST_FAIL,
    REVENUE_FORECAST_REQUEST,
    REVENUE_FORECAST_SUCCESS,
    REVENUE_FORECAST_FAIL,
    FIND_REVENUE_FORECAST_REQUEST,
    FIND_REVENUE_FORECAST_SUCCESS,
    FIND_REVENUE_FORECAST_FAIL,
    UPDATE_REVENUE_FORECAST_REQUEST,
    UPDATE_REVENUE_FORECAST_SUCCESS,
    UPDATE_REVENUE_FORECAST_FAIL,
    CLEAR_REVENUE_FORECAST_ERROR,
  } from "../../constants_Phase_2/RevenueForecast/RevenueForecastConstant";
  import axiosRequest from "../../../services/Interceptor/axiosRequest";
  
  // Create
  export const createRevenueForecast = (data) => async (dispatch) => {
    try {
      dispatch({ type: CREATE_REVENUE_FORECAST_REQUEST });
      const res = await axiosRequest.post(
        `/${process.env.REACT_APP_AUXILIARY_URL}/revenue-forecast/create`,
        data
      );
  
      if (res?.data?.statusCode === 201 || res?.data?.statusCode === 200) {
        dispatch({
          type: CREATE_REVENUE_FORECAST_SUCCESS,
          message: res.data.message,
          status: res.data.statusCode,
        });
      } else {
        dispatch({
          type: CREATE_REVENUE_FORECAST_FAIL,
          message: res.data.message,
          status: res.data.statusCode,
        });
      }
    } catch (error) {
      dispatch({
        type: CREATE_REVENUE_FORECAST_FAIL,
        payload: error,
      });
    }
  };
  export const createRevenueForecastMaintenance = (data) => async (dispatch) => {
    try {
      dispatch({ type: CREATE_REVENUE_FORECAST_REQUEST });
      const res = await axiosRequest.post(
        `/${process.env.REACT_APP_AUXILIARY_URL}/revenue-forecast-maintenance/create`,
        data
      );
  
      if (res?.data?.statusCode === 201 || res?.data?.statusCode === 200) {
        dispatch({
          type: CREATE_REVENUE_FORECAST_SUCCESS,
          message: res.data.message,
          status: res.data.statusCode,
        });
      } else {
        dispatch({
          type: CREATE_REVENUE_FORECAST_FAIL,
          message: res.data.message,
          status: res.data.statusCode,
        });
      }
    } catch (error) {
      dispatch({
        type: CREATE_REVENUE_FORECAST_FAIL,
        payload: error,
      });
    }
  };
  
  
  // Get
  export const getRevenueForecastList = (pageNumber = 0, pageSize = 10) => async (dispatch) => {
      try {
        dispatch({ type: REVENUE_FORECAST_REQUEST });
        const res = await axiosRequest.get(
          `/${process.env.REACT_APP_AUXILIARY_URL}/revenue-forecast/all?pageSize=${pageSize}&pageNumber=${pageNumber}`
        );
  
        dispatch({
          type: REVENUE_FORECAST_SUCCESS,
          payload: res.data.content || [],
          totalData: res?.data?.numberOfElement || 0,
          currentPage: {
            pageNumber,
            pageSize,
          },
          message: res.data.statusCode === 409 ? res.data.message : null,
          status: res.data.statusCode === 409 ? 409 : null,
        });
      } catch (error) {
        dispatch({
          type: REVENUE_FORECAST_FAIL,
          payload: error,
        });
      }
    };
  
  export const getRevenueForecastReviewList = (pageNumber = 0, pageSize = 10) => async (dispatch) => {
      try {
        dispatch({ type: REVENUE_FORECAST_REQUEST });
        const res = await axiosRequest.get(
          `/${process.env.REACT_APP_AUXILIARY_URL}/revenue-forecast/review-all?pageSize=${pageSize}&pageNumber=${pageNumber}`
        );
  
        dispatch({
          type: REVENUE_FORECAST_SUCCESS,
          payload: res.data.content || [],
          totalData: res?.data?.numberOfElement || 0,
          currentPage: {
            pageNumber,
            pageSize,
          },
          message: res.data.statusCode === 409 ? res.data.message : null,
          status: res.data.statusCode === 409 ? 409 : null,
        });
      } catch (error) {
        dispatch({
          type: REVENUE_FORECAST_FAIL,
          payload: error,
        });
      }
    };
  
  export const getRevenueForecastMaintenanceList = () => async (dispatch) => {
      try {
        dispatch({ type: REVENUE_FORECAST_REQUEST });
        const res = await axiosRequest.get(
          `/${process.env.REACT_APP_AUXILIARY_URL}/revenue-forecast-maintenance/approved`
        );
  
        dispatch({
          type: REVENUE_FORECAST_SUCCESS,
          payload: res.data.content || [],
          totalData: res?.data?.numberOfElement || 0,
          message: res.data.statusCode === 409 ? res.data.message : null,
          status: res.data.statusCode === 409 ? 409 : null,
        });
      } catch (error) {
        dispatch({
          type: REVENUE_FORECAST_FAIL,
          payload: error,
        });
      }
    };  
  
  export const getRevenueForecastMaintenance = (pageNumber = 0, pageSize = 10) => async (dispatch) => {
      try {
        dispatch({ type: REVENUE_FORECAST_REQUEST });
        const res = await axiosRequest.get(
          `/${process.env.REACT_APP_AUXILIARY_URL}/revenue-forecast-maintenance/all?pageSize=${pageSize}&pageNumber=${pageNumber}`
        );
  
        dispatch({
          type: REVENUE_FORECAST_SUCCESS,
          payload: res.data.content || [],
          totalData: res?.data?.numberOfElement || 0,
          currentPage: {
            pageNumber,
            pageSize,
          },
          message: res.data.statusCode === 409 ? res.data.message : null,
          status: res.data.statusCode === 409 ? 409 : null,
        });
      } catch (error) {
        dispatch({
          type: REVENUE_FORECAST_FAIL,
          payload: error,
        });
      }
    }; 
  
  export const getRevenueForecastMaintenanceReview = (pageNumber = 0, pageSize = 10) => async (dispatch) => {
      try {
        dispatch({ type: REVENUE_FORECAST_REQUEST });
        const res = await axiosRequest.get(
          `/${process.env.REACT_APP_AUXILIARY_URL}/revenue-forecast-maintenance/review-all?pageSize=${pageSize}&pageNumber=${pageNumber}`
        );
  
        dispatch({
          type: REVENUE_FORECAST_SUCCESS,
          payload: res.data.content || [],
          totalData: res?.data?.numberOfElement || 0,
          currentPage: {
            pageNumber,
            pageSize,
          },
          message: res.data.statusCode === 409 ? res.data.message : null,
          status: res.data.statusCode === 409 ? 409 : null,
        });
      } catch (error) {
        dispatch({
          type: REVENUE_FORECAST_FAIL,
          payload: error,
        });
      }
    };  
  
  // Find
  export const findRevenueForecast = (id) => async (dispatch) => {
    try {
      dispatch({ type: FIND_REVENUE_FORECAST_REQUEST });
      const res = await axiosRequest.get(
        `/${process.env.REACT_APP_AUXILIARY_URL}/revenue-forecast/${id}`
      );
  
      dispatch({
        type: FIND_REVENUE_FORECAST_SUCCESS,
        payload: res.data.content,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } catch (error) {
      dispatch({
        type: FIND_REVENUE_FORECAST_FAIL,
        payload: error,
      });
    }
  };
  
  export const findRevenueForecastMaintenance = (id) => async (dispatch) => {
    try {
      dispatch({ type: FIND_REVENUE_FORECAST_REQUEST });
      const res = await axiosRequest.get(
        `/${process.env.REACT_APP_AUXILIARY_URL}/revenue-forecast-maintenance/${id}`
      );
  
      dispatch({
        type: FIND_REVENUE_FORECAST_SUCCESS,
        payload: res.data.content,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } catch (error) {
      dispatch({
        type: FIND_REVENUE_FORECAST_FAIL,
        payload: error,
      });
    }
  };
  
  // cancel
  export const cancelRevenueForecast = (data, id) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_REVENUE_FORECAST_REQUEST });
      const res = await axiosRequest.put(
        `/${process.env.REACT_APP_AUXILIARY_URL}/revenue-forecast/cancel/${id}`,
        data
      );
  
      dispatch({
        type: UPDATE_REVENUE_FORECAST_SUCCESS,
        payload: res.data.content,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_REVENUE_FORECAST_FAIL,
        payload: error,
      });
    }
  };
  export const cancelRevenueForecastMaintenance = (data, id) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_REVENUE_FORECAST_REQUEST });
      const res = await axiosRequest.put(
        `/${process.env.REACT_APP_AUXILIARY_URL}/revenue-forecast-maintenance/cancel/${id}`,
        data
      );
  
      dispatch({
        type: UPDATE_REVENUE_FORECAST_SUCCESS,
        payload: res.data.content,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_REVENUE_FORECAST_FAIL,
        payload: error,
      });
    }
  };
  
  // update
  export const updateRevenueForecast = (data, id) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_REVENUE_FORECAST_REQUEST });
      const res = await axiosRequest.put(
        `/${process.env.REACT_APP_AUXILIARY_URL}/revenue-forecast/${id}`,
        data
      );
  
      if (res?.data?.statusCode === 201 || res?.data?.statusCode === 200) {
        dispatch({
          type: UPDATE_REVENUE_FORECAST_SUCCESS,
          payload: res.data.content,
          message: res.data.message,
          status: res.data.statusCode,
        });
      } else {
        dispatch({
          type: UPDATE_REVENUE_FORECAST_FAIL, //For conflict
          message: res.data.message,
          status: res.data.statusCode,
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_REVENUE_FORECAST_FAIL,
        payload: error,
      });
    }
  };

  export const updateRevenueForecastReview = (data) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_REVENUE_FORECAST_REQUEST });
      const res = await axiosRequest.put(
        `/${process.env.REACT_APP_AUXILIARY_URL}/revenue-forecast/review`,
        data
      );
  
      if (res?.data?.statusCode === 201 || res?.data?.statusCode === 200) {
        dispatch({
          type: UPDATE_REVENUE_FORECAST_SUCCESS,
          payload: res.data.content,
          message: res.data.message,
          status: res.data.statusCode,
        });
      } else {
        dispatch({
          type: UPDATE_REVENUE_FORECAST_FAIL, //For conflict
          message: res.data.message,
          status: res.data.statusCode,
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_REVENUE_FORECAST_FAIL,
        payload: error,
      });
    }
  };
  
  export const updateRevenueForecastMaintenance = (data, id) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_REVENUE_FORECAST_REQUEST });
      const res = await axiosRequest.put(
        `/${process.env.REACT_APP_AUXILIARY_URL}/revenue-forecast-maintenance/${id}`,
        data
      );
  
      if (res?.data?.statusCode === 201 || res?.data?.statusCode === 200) {
        dispatch({
          type: UPDATE_REVENUE_FORECAST_SUCCESS,
          payload: res.data.content,
          message: res.data.message,
          status: res.data.statusCode,
        });
      } else {
        dispatch({
          type: UPDATE_REVENUE_FORECAST_FAIL, //For conflict
          message: res.data.message,
          status: res.data.statusCode,
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_REVENUE_FORECAST_FAIL,
        payload: error,
      });
    }
  };
  
  export const updateRevenueForecastMaintenanceReview = (data, id) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_REVENUE_FORECAST_REQUEST });
      const res = await axiosRequest.put(
        `/${process.env.REACT_APP_AUXILIARY_URL}/revenue-forecast-maintenance/review/${id}`,
        data
      );
  
      if (res?.data?.statusCode === 201 || res?.data?.statusCode === 200) {
        dispatch({
          type: UPDATE_REVENUE_FORECAST_SUCCESS,
          payload: res.data.content,
          message: res.data.message,
          status: res.data.statusCode,
        });
      } else {
        dispatch({
          type: UPDATE_REVENUE_FORECAST_FAIL, //For conflict
          message: res.data.message,
          status: res.data.statusCode,
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_REVENUE_FORECAST_FAIL,
        payload: error,
      });
    }
  };


  // get All Target Type
  export const getTargetType = () => async (dispatch) => {
    try {
      dispatch({ type: "TARGET_TYPE_REQUEST" });
      const res = await axiosRequest.get(
        `/${process.env.REACT_APP_CORE_URL}/target-type/all`
      );
  
      dispatch({
        type: "TARGET_TYPE_SUCCESS",
        targetType: res?.data?.content
      });
    } catch (error) {
      dispatch({
        type: "TARGET_TYPE_FAIL",
        payload: error,
      });
    }
  };

   // get All Revision
   export const getRevisionQuarter = () => async (dispatch) => {
    try {
      dispatch({ type: "REVISION_QUARTER_REQUEST" });
      const res = await axiosRequest.get(
        `/${process.env.REACT_APP_CORE_URL}/quarter/all`
      );
  
      dispatch({
        type: "REVISION_QUARTER_SUCCESS",
        quarterList: res?.data?.content
      });
    } catch (error) {
      dispatch({
        type: "REVISION_QUARTER_FAIL",
        payload: error,
      });
    }
  };
  
  export const clearError = () => async (dispatch) => {
    dispatch({ type: CLEAR_REVENUE_FORECAST_ERROR });
  };
  