import axiosRequest from "../../../services/Interceptor/axiosRequest";
import {
  CREATE_MAJOR_GROUP_REQUEST,
  CREATE_MAJOR_GROUP_SUCCESS,
  CREATE_MAJOR_GROUP_FAIL,
  MAJOR_GROUP_REQUEST,
  MAJOR_GROUP_SUCCESS,
  MAJOR_GROUP_FAIL,
  FIND_MAJOR_GROUP_REQUEST,
  FIND_MAJOR_GROUP_SUCCESS,
  FIND_MAJOR_GROUP_FAIL,
  UPDATE_MAJOR_GROUP_REQUEST,
  UPDATE_MAJOR_GROUP_SUCCESS,
  UPDATE_MAJOR_GROUP_FAIL,
  DELETE_MAJOR_GROUP_REQUEST,
  DELETE_MAJOR_GROUP_SUCCESS,
  DELETE_MAJOR_GROUP_FAIL,
  CLEAR_ERROR,
} from "../../constants/COAMasters/MajorGroupConstant";

// create
export const createMajorGroup = (data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_MAJOR_GROUP_REQUEST });

    const res = await axiosRequest.post(
      `/${process.env.REACT_APP_CORE_URL}/coa-major-group/create`,
      data
    );

    if (res?.data?.statusCode !== 409) {
      dispatch({
        type: CREATE_MAJOR_GROUP_SUCCESS,
        payload: res.data.content,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } else {
      dispatch({
        type: CREATE_MAJOR_GROUP_FAIL, //For conflict
        message: res.data.message,
        status: res.data.statusCode,
      });
    }
  } catch (error) {
    dispatch({
      type: CREATE_MAJOR_GROUP_FAIL,
      payload: error,
    });
  }
};

// get
export const getMajorGroups = () => async (dispatch) => {
  try {
    dispatch({ type: MAJOR_GROUP_REQUEST });

    const res = await axiosRequest.get(
      `/${process.env.REACT_APP_CORE_URL}/coa-major-group/all`
    );

    dispatch({
      type: MAJOR_GROUP_SUCCESS,
      payload: res.data.content,
    });
  } catch (error) {
    dispatch({
      type: MAJOR_GROUP_FAIL,
      payload: error,
    });
  }
};

// find
export const findMajorGroup = (id) => async (dispatch) => {
  try {
    dispatch({ type: FIND_MAJOR_GROUP_REQUEST });

    const res = await axiosRequest.get(
      `/${process.env.REACT_APP_CORE_URL}/coa-major-group/${id}`
    );

    dispatch({
      type: FIND_MAJOR_GROUP_SUCCESS,
      payload: res.data.content,
    });
  } catch (error) {
    dispatch({
      type: FIND_MAJOR_GROUP_FAIL,
      payload: error,
    });
  }
};

// update
export const updateMajorGroup = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_MAJOR_GROUP_REQUEST });

    const res = await axiosRequest.put(
      `/${process.env.REACT_APP_CORE_URL}/coa-major-group/${id}`,
      data
    );

    if (res?.data?.statusCode !== 409) {
      dispatch({
        type: UPDATE_MAJOR_GROUP_SUCCESS,
        payload: res.data.content,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } else {
      dispatch({
        type: UPDATE_MAJOR_GROUP_FAIL, //For conflict
        message: res.data.message,
        status: res.data.statusCode,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_MAJOR_GROUP_FAIL,
      payload: error,
    });
  }
};

// delete
export const deleteMajorGroup = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_MAJOR_GROUP_REQUEST });

    const res = await axiosRequest.put(
      `/${process.env.REACT_APP_CORE_URL}/coa-major-group/delete/${id}`,
      formData
    );

    if (
      res?.data?.statusCode !== 409 &&
      res?.data?.content?.isDelete === true
    ) {
      dispatch({
        type: DELETE_MAJOR_GROUP_SUCCESS,
        payload: id,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } else if (
      res?.data?.statusCode !== 409 &&
      res?.data?.content?.isDelete === false
    ) {
      dispatch({
        type: "FUTURE_DELETE_MAJOR_GROUP_SUCCESS",
        payload: res?.data?.content,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } else {
      dispatch({
        type: DELETE_MAJOR_GROUP_FAIL,
        message: res.data.message,
        status: res.data.statusCode,
      });
    }
  } catch (error) {
    dispatch({
      type: DELETE_MAJOR_GROUP_FAIL,
      payload: error,
    });
  }
};

export const clearError = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERROR });
};
