import {
  CREATE_STATUS_REQUEST,
  CREATE_STATUS_SUCCESS,
  CREATE_STATUS_FAIL,
  STATUS_REQUEST,
  STATUS_SUCCESS,
  STATUS_FAIL,
  FIND_STATUS_REQUEST,
  FIND_STATUS_SUCCESS,
  FIND_STATUS_FAIL,
  UPDATE_STATUS_REQUEST,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_FAIL,
  DELETE_STATUS_REQUEST,
  DELETE_STATUS_SUCCESS,
  DELETE_STATUS_FAIL,
  STATUS_CLEAR_ERROR,
} from "../../constants/OthersMaster/StatusMasterConstant";
import axiosRequest from "../../../services/Interceptor/axiosRequest";

//api call
//Add STATUS
export const createStatusMaster = (data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_STATUS_REQUEST });
    const res = await axiosRequest.post(
      `/${process.env.REACT_APP_CORE_URL}/status/create`,
      data
    );
    if (res?.data?.statusCode !== 409) {
      dispatch({
        type: CREATE_STATUS_SUCCESS,
        payload: res.data.content,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } else {
      dispatch({
        type: CREATE_STATUS_FAIL, //For conflict
        message: res.data.message,
        status: res.data.statusCode,
      });
    }
  } catch (error) {
    dispatch({
      type: CREATE_STATUS_FAIL,
      payload: error,
    });
  }
};

//Get All data in STATUS
export const getStatusMaster = () => async (dispatch) => {
  try {
    dispatch({ type: STATUS_REQUEST });
    const { data } = await axiosRequest.get(
      `/${process.env.REACT_APP_CORE_URL}/status/all`
    );
    dispatch({ type: STATUS_SUCCESS, payload: data.content });
  } catch (error) {
    dispatch({
      type: STATUS_FAIL,
      payload: error,
    });
  }
};

//Get Find Data in STATUS
export const findStatusMaster = (id) => async (dispatch) => {
  try {
    dispatch({ type: FIND_STATUS_REQUEST });
    const res = await axiosRequest.get(
      `/${process.env.REACT_APP_CORE_URL}/status/${id}`
    );
    dispatch({ type: FIND_STATUS_SUCCESS, payload: res.data.content });
  } catch (error) {
    dispatch({
      type: FIND_STATUS_FAIL,
      payload: error,
    });
  }
};

//Update Find Data in STATUS
export const updateStatusMaster = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_STATUS_REQUEST });
    const res = await axiosRequest.put(
      `/${process.env.REACT_APP_CORE_URL}/status/${id}`,
      data
    );

    if (res?.data?.statusCode !== 409) {
      dispatch({
        type: UPDATE_STATUS_SUCCESS,
        payload: res.data.content,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } else {
      dispatch({
        type: UPDATE_STATUS_FAIL, //For conflict
        message: res.data.message,
        status: res.data.statusCode,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_STATUS_FAIL,
      payload: error,
    });
  }
};

//delete Find Data in STATUS
export const deleteStatusMaster = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_STATUS_REQUEST });
    const res = await axiosRequest.put(
      `/${process.env.REACT_APP_CORE_URL}/status/delete/${id}`,
      formData
    );

    if (
      res?.data?.statusCode !== 409 &&
      res?.data?.content?.isDelete === true
    ) {
      dispatch({
        type: DELETE_STATUS_SUCCESS,
        payload: id,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } else if (
      res?.data?.statusCode !== 409 &&
      res?.data?.content?.isDelete === false
    ) {
      dispatch({
        type: "FUTURE_STATUS_SUCCESS",
        payload: res?.data?.content,
        message: res.data.message,
        status: res.data.statusCode,
      });
    } else {
      dispatch({
        type: DELETE_STATUS_FAIL,
        message: res.data.message,
        status: res.data.statusCode,
      });
    }
  } catch (error) {
    dispatch({
      type: DELETE_STATUS_FAIL,
      payload: error,
    });
  }
};

export const clearError = () => async (dispatch) => {
  dispatch({ type: STATUS_CLEAR_ERROR });
};
